import * as moment from 'moment';
import 'moment-duration-format';
import { Deserializable } from './deserializable';
import { Module } from './module';
import { Question } from './question';
// import { UserModule } from './user-module';

export class Exam extends Module implements Deserializable {
  num_questions?: number;
  questions?: Question[];
  user_modules?: any[];
  type?: string = 'exam';

  get training_time() {
    return moment['duration'](this._training_time, 'minutes')['format']('m:ss');
  }

  set training_time(time) {
    this._training_time = time;
  }

  declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  }

  get formatted_num_questions() {
    return this.num_questions + ' ' + this.declOfNum(this.num_questions, ['вопрос', 'вопроса', 'вопросов']);
  }

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);

    // if (input.user_modules) {
    //   const user_modules: UserModule[] = [];
    //   input.user_modules.map(data => {
    //     user_modules.push(new UserModule().deserialize(data));
    //   });
    //   this.user_modules = user_modules;
    // }

    return this;
  }
}
