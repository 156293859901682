import { Deserializable } from './deserializable';

export class UserCourseModule implements Deserializable {
  id?: number;
  modulable_id?: string;
  modulable_type?: string;
  course_id?: number;
  points?: string;
  start?: string;
  end?: string;
  passed?: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
