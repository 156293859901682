import * as moment from 'moment';
import 'moment-duration-format';
import { Deserializable } from './deserializable';
import { UserAnswer } from './user-answer';

export class UserModule implements Deserializable {
  get spended_time(): number {
    return moment['duration'](this._spended_time, 'seconds')['format']('m:ss');
  }

  set spended_time(value: number) {
    this._spended_time = value;
  }

  id?: number;
  modulable_id?: string;
  modulable_type?: string;
  points?: number;
  progress?: number;
  start?: string;
  end?: string;
  passed?: boolean;
  charged?: boolean;
  _spended_time?: number;
  user_answers?: UserAnswer[];

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.user_answers) {
      const user_answers: UserAnswer[] = [];
      input.user_answers.map((data) => {
        user_answers.push(new UserAnswer().deserialize(data));
      });
      this.user_answers = user_answers;
    }
    return this;
  }
}
