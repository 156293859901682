import { Content } from './../../core/models/content';
import { User } from '@Mesh/core/models/user';
import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';

export enum POSTRequestStatus {
  NONE,
  SENT,
  SUCCESS,
  FAILURE,
}

export interface UserState extends Loadable {
  users: User[];
  usersTotalCount: number;
  newUserId: number;
  selectedUserId: number;
  statuses: {
    smsRemoveUser: POSTRequestStatus;
    verifyAddUser: POSTRequestStatus;
    verifyRemoveUser: POSTRequestStatus;
    sentInvitationAddNewUser: POSTRequestStatus;
    updateUser: POSTRequestStatus;
    checkExistenceUser: POSTRequestStatus;
    upsertPermissions: POSTRequestStatus;
    retrieveclientIds: POSTRequestStatus;
  };
  isExistingUser: boolean;
  clientIds: number[];
  errors: any[] | null;
  usersTotalPages: number;
  usersCurrentPage: number;
  userLoading: boolean;
  content: Content;
}

export const initialState: UserState = {
  ...createDefaultLoadable(),
  users: [],
  usersTotalCount: 0,
  newUserId: null,
  selectedUserId: -1,
  statuses: {
    smsRemoveUser: POSTRequestStatus.NONE,
    verifyAddUser: POSTRequestStatus.NONE,
    verifyRemoveUser: POSTRequestStatus.NONE,
    sentInvitationAddNewUser: POSTRequestStatus.NONE,
    updateUser: POSTRequestStatus.NONE,
    checkExistenceUser: POSTRequestStatus.NONE,
    upsertPermissions: POSTRequestStatus.NONE,
    retrieveclientIds: POSTRequestStatus.NONE,
  },
  isExistingUser: null,
  clientIds: [],
  errors: null,
  usersTotalPages: 0,
  usersCurrentPage: 0,
  userLoading: false,
  content: null,
};
