// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
};

export const IMAGES_URL = 'https://cdn.stage.itg.iql.ru/';
export const API_PLAN_URL = 'https://saleplan.stage.itg.iql.ru/api';
export const API_USER_URL = 'https://user.stage.itg.iql.ru/api/v1';
export const API_TASK_URL = 'https://task.stage.itg.iql.ru/api/v1';
export const API_SCHOOL_URL = 'https://api.iqschool.tech.iql.ru/api';
export const IMAGE_CLOUD_URL = 'https://static.cloud.stage.itg.iql.ru';
export const IMAGE_URL = 'http://cloud.stage.itg.iql.ru/api/v1';
export const MESSAGING_URL = 'https://notifications.stage.itg.iql.ru/api/v1';
export const MESSAGING_WEBSOCKET_URL = 'wss://notifications.stage.itg.iql.ru/iq-messaging/socket/v1/';
export const WEATHER_API_KEY = '500817745447077068ff18c313b11948';
export const WEATHER_API_URL = 'assets/data/weather.json';
export const YANDEX_METRIKA_KEY: number = 0;
export const FACEBOOK_APP_ID = '537159916670072';
export const NODE_URL = 'https://chat.stage.itg.iql.ru';
export const CHAT_SCHOOL_URL = 'https://chat.iqschool.tech.iql.ru';
export const CLOUD_UPLOAD_DOC_URL = 'https://cloud.stage.itg.iql.ru/api/v1/content/doc';

export const FIREBASE = {
  apiKey: 'AIzaSyALscl4Vg8Hw5BGhns3QnyMQq1-dAjEuBM',
  authDomain: 'academy-megapolis.firebaseapp.com',
  databaseURL: 'https://academy-megapolis.firebaseio.com',
  projectId: 'academy-megapolis',
  storageBucket: 'academy-megapolis.appspot.com',
  messagingSenderId: '25386312670',
  appId: '1:25386312670:web:83f5d854ec9266b967b34f',
  measurementId: 'G-QQ4R97ZT9Z',
};
