import { EventEmitter, Injectable } from '@angular/core';
import { map, first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { from, Subject } from 'rxjs';
import { User } from '@Mesh/core/models/user';
import { ChatMessage } from './models/chat-message';
import { ChatLikesService } from '@Mesh/core/services/chat/chat-likes.service';
import { ChatSettingsService } from '@Mesh/core/services/chat/chat-settings.service';
import { MessagesService } from '@Mesh/core/services/chat/messages.service';
import { SoundService, Sounds } from '@Mesh/core/services/chat/sound.service';
import { UserService } from '@Mesh/core/services/user.service';
import { API_PLAN_URL, API_TASK_URL } from '@Env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UpdateLeftoverPayload } from '../../../core/models/update-leftover-products';

export class ChatPager {
  page?: number;
  skip?: number;
  take?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  updateDialogs = new EventEmitter();
  userChatSubscription$: Subject<Observable<ChatMessage[]>> = new Subject();
  recognitionSectionToggle = false;
  planId = [];
  materialId = [];
  stepId: number;
  addressId: number;
  recognitionResultId: any;
  cigarettesShowcaseImg = '';
  constructor(
    private http: HttpClient,
    private feathers: FeathersService,
    private userService: UserService,
    private messagesService: MessagesService,
    private chatSettings: ChatSettingsService,
    private chatLikesService: ChatLikesService,
    private feathersService: FeathersService,
    private soundService: SoundService
  ) {}
  readonly taskUrl = `${API_TASK_URL}`;
  readonly baseUrl = `${API_PLAN_URL}/v1`;

  user: User;

  getUser(userId) {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('users')
        .get(userId)
    );
  }

  getSupportUser(): Observable<any> {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('/user-support')
        .find({})
    );
  }

  getUserByclientId(clientId) {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('users')
        .find({ query: { clientId }, paginate: false })
    ).pipe(
      map((users: any) => {
        users = Array.isArray(users) ? users : users.data;
        return users[0];
      })
    );
  }

  getTaskById(id) {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('tasks')
        .get(id)
    ).pipe(
      tap((task) => {
        return task;
      })
    );
  }

  getOutletByaddressId(addressId) {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('outlets')
        .find({ query: { addressId }, paginate: false })
    ).pipe(
      map((outlets: any) => {
        outlets = Array.isArray(outlets) ? outlets : outlets.data;
        return outlets[0];
      })
    );
  }

  getTaskUser(id: number) {
    return this.feathers.service('task-user').get(id);
  }

  getTaskStep(id) {
    return from(this.feathers.service('task-steps').get(id)).pipe(
      tap((taskStep) => {
        return taskStep;
      })
    );
  }

  getTaskSteps(stepId: number, query: { addressId: number; taskOutletClientId: number }): Observable<any> {
    return from(this.feathers.service('users-chat/task-step').get(stepId, { query }));
  }

  getModule(type: string, id: number) {
    return this.feathers.service('users-chat/module/' + type).get(id);
  }

  getUsers({ page = 0, omit_ids = [], searchQuery = null }) {
    let limit = 20;

    let query = {
      /* $sort: {
        name: 1
      }, */
      // $select: ['id', 'name', 'patronymic', 'positionId', 'birthPlace', 'avatarId', 'surname'],
      // readed: false
      $limit: limit,
      $skip: page * limit,
      name: {
        $ne: '',
      },
      /* id: {
        $nin: omit_ids,
      }, */
      /* dismissalDate: {
        $in: [ null ]
      } */
    };
    if (searchQuery) {
      query['$like'] = searchQuery;
    }

    return this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
      .service('users')
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({
        query,
      });
  }

  getChatCommunity({ type }) {
    return new Observable((observer) => {
      let community;
      this.feathers.service('messages/:type/:typeId').on('created', (msg) => {
        if (community && community.type === type && community.info.id === msg.typeId) {
          community.messages.pop();
          community.messages.unshift(msg);
          observer.next(
            (community = {
              ...community,
            })
          );
        }
      });

      this.userService
        .user(false)
        .flatMap((user) => {
          this.user = user;
          return this.getCommunity({
            type,
            type_id: ['guild', 'clan', 'alliance'].includes(type) ? user[type].id : 0,
            limit: 2,
          }).pipe(
            map(
              (messages) => ({
                type,
                info: ['guild', 'clan', 'alliance'].includes(type) ? user[type] : { name: 'Общий чат', id: 0 },
                messages,
              }),
              first()
              // catchError(err => of(null))
            )
          );
        })
        .subscribe((_community) => {
          community = _community;
          observer.next(community);
        });
    });
  }

  getCommunity({ type, type_id, page = 0, limit = 100 }: any) {
    return this.feathers
      .service(`messages/${type}/${type_id}`)
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({
        query: {
          $limit: limit,
          $skip: page * limit,
          // participant: user_id
          $sort: {
            createdAt: -1,
          },
        },
      })
      .pipe(map(({ data }) => data));
  }

  getAvailableDialogs({ page = 0, take = 10, skip = 0 }: ChatPager = {}): Observable<{ total: number; data: any[] }> {
    const $limit = take;
    const $skip = skip;
    this.messagesService.findChats({ type: 'all', $limit, $skip });
    return this.messagesService.subjects.getObs('users-chat/list');
  }

  togglePin(dialog, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.togglePin(dialog, type, typeId, flag);
  }

  toggleSound(dialog, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.toggleSound(dialog, type, typeId, flag);
  }

  removeDialog(dialog, type: string, typeId: number): any {
    return this.chatSettings.removeDialog(dialog, type, typeId);
  }

  toggleLike(type: string, typeId: number, messageId: number, isLikeOrDislike: boolean) {
    this.soundService.play(Sounds.CHAT_MESSAGE_LIKED);
    this.chatLikesService.toggleLike(type, typeId, messageId, isLikeOrDislike);
  }
  recognitionResult(id): any {
    return this.http.get(`${this.taskUrl}/recognition-result/by-task-success-id/${id}`);
  }
  recognizedProducts(materialId?, addressId?): Observable<any> {
    let params = new HttpParams();
    if (materialId && addressId) {
      params = params.append('materialIds', `${materialId}`);
      params = params.append('addressId', `${addressId}`);
    } else {
      params = params.append('planId', `${this.planId}`);
      params = params.append('pageSize', '200');
      params = params.append('addressId', `${this.addressId}`);
    }
    params = params.append('ignoreStock', 'true');
    return this.http.get(`${this.baseUrl}/product`, { params });
  }

  getStockProducts(params: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/product`, { params });
  }

  setLeftoverProducts(payload: UpdateLeftoverPayload): Observable<any> {
    return this.http.put(`${this.baseUrl}/leftover`, payload);
  }

  openRecognition(message) {
    const images = message?.attached?.uploads?.filter((item) => ['jpg', 'png'].indexOf(item.ext) !== -1);
    this.recognitionResultId = message.taskStep.stepSuccess.recognitionResult.id;
    this.cigarettesShowcaseImg = images[0].url;
    this.recognitionSectionToggle = true;
  }
  closeRecognition() {
    this.recognitionSectionToggle = false;
  }
  cancelResult() {
    return this.http.put(`${this.taskUrl}/task/success/cancel-result`, {
      addressId: this.addressId,
      stepId: this.stepId,
    });
  }

  acceptResult() {
    return this.http.put(`${this.taskUrl}/task/success/accept-result`, {
      addressId: this.addressId,
      stepId: this.stepId,
    });
  }
  acceptRecognition(coordinates) {
    return this.http.put(`${this.taskUrl}/recognition-result/${this.recognitionResultId}/add-coord`, coordinates);
  }
}
