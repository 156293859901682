import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { Observable, Subject } from 'rxjs';
import { Order, OrderStatus } from '@Mesh/core/models/order';
import { getOrdersWithoutDeliveredTrue, orderSelectors } from '@Mesh/store/order/order.selectors';
import { Outlet } from '@Mesh/core/models';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import {
  OrderLoadPageRequest,
  OrderLoadRequest,
  OrderSetActive,
  OrderSetConfirmed,
  RemoveDelayedOrder,
  RequestInvoiceDelayedOrder,
} from '@Mesh/store/order/order.actions';
import { getGetStartDateAndFinishDate } from '@Mesh/core/services/util';
import { Router } from '@angular/router';
import { DelayedOrder } from '@Mesh/core/models/delayed-order';
import { DelayedOrderNotifyDropComponent } from '@Mesh/pages/active/order/delayed-order-notify-drop/delayed-order-notify-drop.component';
import { takeUntil } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DelayedOrderHistoryComponent } from '@Mesh/pages/active/order/delayed-order-history/delayed-order-history.component';
import { DelayOrderRequestInvoiceComponent } from '@Mesh/pages/active/order/delay-order-request-invoice/delay-order-request-invoice.component';

@Component({
  selector: 'app-active-orders-widget',
  templateUrl: './active-orders-widget.component.html',
  styleUrls: ['./active-orders-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveOrdersWidgetComponent implements OnInit, OnDestroy {
  orders$: Observable<Order[]> = this.store.select(getOrdersWithoutDeliveredTrue);
  activeOutlet$: Observable<Outlet> = this.store.select(outletSelectors.getActive);
  details$ = this.store.select(orderSelectors.getDetailedOrder);
  getActiveOrdersCurrentPage$ = this.store.select(orderSelectors.getActiveOrdersCurrentPage);
  totalActiveOrdersPages$ = this.store.select(orderSelectors.getOrderTotalPages);
  activeOrdersTotalCount$ = this.store.select(orderSelectors.getOrderTotalCount);
  delayedOrders$ = this.store.select(orderSelectors.getDelayedOrders);
  getOrdersUsers$ = this.store.select(orderSelectors.getOrdersUsers);
  ordersProducts$ = this.store.select(orderSelectors.getOrdersProducts);

  bsModalRef: any;

  private unsubscribe$ = new Subject();

  constructor(private store: Store<AppState>, private router: Router, private modalService: BsModalService) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  getOrderDetails(docNumber: string) {
    this.store.dispatch(new OrderSetActive({ docNumber }));
  }

  loadAllActiveOrders() {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();

    this.store.dispatch(
      new OrderLoadRequest({
        pageNumber: 0,
        sort: [],
        pageSize: 9999,
        startDate,
        finishDate,
        status: [OrderStatus.NEW, OrderStatus.APPROVED],
      })
    );
  }

  loadActiveOrdersPages(page) {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();
    this.store.dispatch(new OrderLoadPageRequest({ page: page, itemsPerPage: 10, startDate: startDate, finishDate: finishDate }));
  }

  navToActiveOrders() {
    this.router.navigate(['active', 'order']);
  }

  navigateToCatalog() {
    this.router.navigate(['catalog']);
  }

  onConfirmedDeliveryOrder(docNumber: string) {
    this.store.dispatch(new OrderSetConfirmed({ docNumber }));
  }

  onEditedDelayedOrder(delayedOrder: DelayedOrder): void {
    this.router.navigate(['/active/delayed-order-edit'], {
      queryParams: { addressId: delayedOrder.addressId, delayedOrderId: delayedOrder.id },
      queryParamsHandling: 'merge',
    });
  }

  onDropedDelayedOrder(delayedOrderId: number) {
    this.bsModalRef = this.modalService.show(DelayedOrderNotifyDropComponent, {
      class: 'modal-dialog-centered modal-notify',
    });
    this.bsModalRef.content.canceled.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
    this.bsModalRef.content.droped.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.store.dispatch(new RemoveDelayedOrder({ delayedOrderId }));
      this.onClosedModal();
    });
  }

  onShowHistoryDelayedOrder(delayedOrder: DelayedOrder): void {
    this.bsModalRef = this.modalService.show(DelayedOrderHistoryComponent, {
      class: 'modal-dialog-right',
      initialState: { orderHistories: delayedOrder.history },
    });
    this.bsModalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
  }

  onRequestInvoiceDelayedOrder(delayedOrderId: number) {
    this.store.dispatch(new RequestInvoiceDelayedOrder({ delayedOrderId, shouldRequestInvoice: true }));
    this.bsModalRef = this.modalService.show(DelayOrderRequestInvoiceComponent, {
      class: 'modal-dialog-centered modal-notify',
    });
    setTimeout(() => {
      this.bsModalRef.hide();
    }, 2000);
  }

  onClosedModal() {
    this.bsModalRef?.hide();
  }
}
