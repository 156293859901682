import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionWidgetComponent } from './auction-widget.component';
import { UserInfoModule } from '../../../../shared/modules/user-school-info/user-info.module';

@NgModule({
  declarations: [AuctionWidgetComponent],
  exports: [AuctionWidgetComponent],
  imports: [CommonModule, UserInfoModule],
})
export class AuctionWidgetModule {}
