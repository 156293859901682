import { Deserializable } from './deserializable';
import { Certificate } from './certificate';

export class PassedEntity implements Deserializable {
  id?: number;
  passed?: boolean;
  charged?: boolean;
  progress?: number;
  goblet?: boolean;
  certificate?: Certificate;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input) {
      if (input.certifcate) {
        this.certificate = new Certificate().deserialize(input.certifcate);
      }
    }
    return this;
  }
}
