import { AgentUser } from './agent-user';
import { AsyncItem } from './async-item';
import { Deserializable } from './deserializable';
import { Client } from '@Mesh/core/models/client';
import { UserDistributorRole } from './user-distributor-role';

export class User implements Deserializable {
  username: string;
  avatar: string;
  email: string;
  client: Client;
  id: number;
  surname: string;
  name: string;
  patronymic: string;
  phone: string;
  possiblePhone?: string;
  status: string;
  type: 'OWNER' | 'EMPLOYEE' | 'MANAGER';
  position: string;
  sex: string;
  birthday: string;
  createdAt: string;
  updatedAt: string;
  schoolExternalId: string;
  timezone: string;
  distributorRoles: IUserDistributorRole[];
  agentUsers: AgentUser[];
  clientAddressPermissions: ClientAddressPermission[];
  iqretailId: number;
  permissions?: { addressId: number; permissions: ('ADD_USER' | 'ORDR_TSK' | 'ADDR_BOSS')[] }[];
  permissionsIcons?: string[];

  declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  }

  get short_name(): string {
    if (this.client.name) {
      return this.client.name.substr(0, 1).toUpperCase();
    }
  }

  get town(): string {
    const regex = /(г(.*))(ул)/gm;
    const groups = regex.exec(this.client?.address);
    return groups !== null && groups.length > 0 ? groups[1] : '';
  }

  get full_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name);
    }
    if (this.surname) {
      result.push(this.surname);
    }
    return result.length ? result.join(' ') : '';
  }

  deserialize(input: any): this {
    if (!input) {
      return null;
    }
    Object.assign(this, input);
    return this;
  }
}

export interface IUserInfo {
  accessTokenExpires: number;
  refreshTokenExpires: number;
  accessToken: string;
  refreshToken: string;
  user: User;
}
export interface IUserInfoResponse {
  accessTokenExpires: number;
  refreshTokenExpires: number;
  access_token: string;
  refresh_token: string;
  user: User;
}

export interface UsersSearchParams {
  distrIds: number[];
  ids: number[];
  search: string;
  agentPositionIds: number[];
  agentDivisionIds: number[];
  agentWithDismissal: boolean;
  addressCountryIds: number[];
  addressRegionIds: number[];
  addressCityIds: number[];
  addressPostalCodeStreetIds: number[];
  addressPermissions: string[];
  pageSize: number;
  pageNumber: number;
  sort: string[];
  addressClientIds: number[];
  addressIds: number[];
}

export interface ClientAddressPermission {
  userId: number;
  clientId: number;
  addressId: number;
  permissions: string[];
}

export interface UserCreatePayload {
  phone: string;
  username: string;
  surname: string;
  name: string;
  patronymic: string;
  email: string;
  timezone: string;
  sex: string;
  birthday: string;
}

export interface UserUpdatePayload {
  phone: string;
  username: string;
  surname: string;
  name: string;
  patronymic: string;
  email: string;
  timezone: string;
  sex: string;
  birthday: string;
  id: number;
  password: string;
  status: string;
}

export interface UserRemoveParams {
  id: number;
}

export enum UserStatusType {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  INACTIVE = 'INACTIVE',
}

export interface DistributorRole {
  name?: string;
  id: number;
  distributor: UserDistributor;
  role: UserDistributorRole;
  owner: boolean;
}

export interface UserDistributor {
  id: number;
  parent: any;
  externalId: any;
  name: string;
  description: any;
  image: any;
  slug: string;
}

export interface IUserDistributorRole {
  id: number;
  name: string;
  permissions: UserDistributorPermission[];
}

export interface UserDistributorPermission {
  id: number;
  entityType: string;
  permission: string;
}

export enum UserDistributorPermissionType {
  VIEW = 'VIEW_RECORD',
  CREATE = 'CREATE_RECORD',
  UPDATE = 'UPDATE_RECORD',
  DELETE = 'DELETE_RECORD',
}

export enum UserDistributorRoleType {
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  TYPE_1 = 'TYPE_1',
  TYPE_2 = 'TYPE_2',
  ASM = 'ASM',
}

export enum UserType {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  EXCHANGER = 'EXCHANGER',
  OWNER = 'OWNER',
}
// OWNER, MANAGER, EMPLOYEE, ADMIN, EXCHANGER, AGENT, SUPERVISOR, REGION_MANAGER

export enum UserStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  UNKNOWN = 'UNKNOWN',
}

export const UserStatusMap = {
  [UserStatus.NEW]: {
    type: UserStatus.NEW,
    title: 'Новый',
    color: '#FFD600',
  },
  [UserStatus.ACTIVE]: {
    type: UserStatus.ACTIVE,
    title: 'Активен',
    color: '#00D0B3;',
  },
  [UserStatus.INACTIVE]: {
    type: UserStatus.INACTIVE,
    title: 'Неактивен',
    color: '#FF5254',
  },
  [UserStatus.DRAFT]: {
    type: UserStatus.DRAFT,
    title: 'Черновик',
    color: '#FF8C4D',
  },
  // [UserStatus.UNKNOWN]: {
  //   type: UserStatus.UNKNOWN,
  //   title: 'Неизветсно',
  //   color: '#FF5254'
  // },
};

export const UserTypeMap = {
  [UserType.AGENT]: {
    type: UserType.AGENT,
    title: 'Торговый агент',
  },
  [UserType.ADMIN]: {
    type: UserType.ADMIN,
    title: 'Супер адмнинистратор',
  },
  [UserType.EXCHANGER]: {
    type: UserType.EXCHANGER,
    title: 'Сотрудник дистрибьютора',
  },
};

export const AllowWriteUserTypeList = [
  UserDistributorRoleType.ADMIN,
  UserDistributorRoleType.OWNER,
  UserDistributorRoleType.TYPE_1,
  UserDistributorRoleType.ASM,
];

export type AsyncUser = AsyncItem<User>;
