import { Module, IModule } from './module';

export interface IBook extends IModule {
  type?: string;
  image?: {
    list: string;
    view: string;
  };
}

export class Book extends Module implements IBook {
  type?: string = 'book';
  image?: {
    list: string;
    view: string;
  };

  constructor(book?: Partial<IBook>) {
    super(book);
    if (!book) {
      return;
    }
    this.image = book.image;
  }

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    return this;
  }
}
