import { Deserializable } from './deserializable';
import { User } from './user';

export class Certificate implements Deserializable {
  id?: number;
  image?: string;
  pdf?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
