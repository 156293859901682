import { Deserializable } from './deserializable';

export class UserCourseModuleAllow implements Deserializable {
  id?: number;
  user_id?: number;
  coursable_id?: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
