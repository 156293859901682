import * as moment from 'moment';
import 'moment-duration-format';
import { Article } from './article';
import { Book } from './book';
import { Course } from './course';
import { Deserializable } from './deserializable';
import { Exam } from './exam';
import { Material } from './material';
import { Tag } from './tag';
import { TaskLevel } from './task-level';
import { UserSchool } from './user-school';
import { Video } from './video';

export class TaskSchool implements Deserializable {
  get training_time() {
    return moment['duration'](this._training_time, 'seconds')['format']('m:ss');
  }

  set training_time(time) {
    this._training_time = time;
  }

  id?: number;
  name?: string;
  _training_time?: number;
  max_points?: number;
  description?: string;
  module_type?: string;
  module_id?: number;
  module?: any;
  director?: UserSchool;
  tags?: Tag[];
  materials?: Material[];
  is_grade: boolean;
  complexity?: number;
  image_goblet?: string;
  level?: TaskLevel;
  modulable?: any;

  get module_name() {
    return {
      article: 'Статья',
      book: 'Книга',
      course: 'Программа обучения',
      exam: 'Тестирование',
      video: 'Видео',
    }[this.module_type];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.director) {
      this.director = new UserSchool().deserialize(input.director);
    }
    if (input.level) {
      let level = new TaskLevel();
      level.deserialize(input.level);
      this.level = level;
    }
    if (input.module) {
      switch (input.module_type) {
        case 'article':
          this.module = new Article().deserialize(input.module);
          break;
        case 'book':
          this.module = new Book().deserialize(input.module);
          break;
        case 'course':
          this.module = new Course().deserialize(input.module);
          break;
        case 'exam':
          this.module = new Exam().deserialize(input.module);
          break;
        case 'video':
          this.module = new Video().deserialize(input.module);
          break;
      }
    }
    if (input.tags) {
      const tags: Tag[] = [];
      input.tags.map((data) => {
        tags.push(new Tag().deserialize(data));
      });
      this.tags = tags;
    }
    if (input.materials) {
      const materials: Material[] = [];
      input.materials.map((data) => {
        materials.push(new Material().deserialize(data));
      });
      this.materials = materials;
    }
    return this;
  }
}
