import { Deserializable } from './deserializable';
import { UserCourseModuleAllow } from './user-course-module-allow';
import { UserModule } from './user-module';

export class UserCourse implements Deserializable {
  id?: number;
  start?: string;
  end?: string;
  points?: number;
  passed?: boolean;
  progress?: number;
  user_course_module_allows?: UserCourseModuleAllow[];
  user_course_modules?: UserModule[];

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.user_course_module_allows) {
      const user_course_module_allows: UserCourseModuleAllow[] = [];
      input.user_course_module_allows.map((data) => {
        user_course_module_allows.push(new UserCourseModuleAllow().deserialize(data));
      });
      this.user_course_module_allows = user_course_module_allows;
    }

    if (input.user_course_modules) {
      const user_course_modules: UserModule[] = [];
      input.user_course_modules.map((data) => {
        user_course_modules.push(new UserModule().deserialize(data));
      });
      this.user_course_modules = user_course_modules;
    }
    return this;
  }
}
