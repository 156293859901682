import { Content } from './../../core/models/content';
import { Action } from '@ngrx/store';
import { User } from '@Mesh/core/models/user';
import {
  DeleteUserCriteria,
  GetUserCriteria,
  PostSmsUser,
  PostUpdateUser,
  PostUser,
  PostUserPermissions,
  PostVerifyUser,
} from '@Mesh/core/models/request';

export enum UserActionsTypes {
  UsersLoadRequest = '[USER] Load users request',
  UsersLoadSuccess = '[USER] Load users success',
  UsersLoadError = '[USER] User error',

  UsersLoadPageRequest = '[USER] User load page request',
  UsersLoadPageRequestFailure = '[USER] User load page request failure',

  UsersTotalCount = '[USER] Users total count',

  AddUserRequest = '[USER] Add user request',
  AddUserSuccess = '[USER] Add user success',
  AddUserError = '[USER] Add user error',

  SendSmsRemoveUserRequest = '[USER] Send sms remove user request',
  SendSmsRemoveUserSuccess = '[USER] Send sms remove user success',
  SendSmsRemoveUserError = '[USER] Send sms remove user error',

  VerifyAddNewUserRequest = '[USER] Verify add new user request',
  VerifyAddNewUserSuccess = '[USER] Verify add new user success',
  VerifyAddNewUserError = '[USER] Verify add new user error',

  VerifyRemoveUserRequest = '[USER] Verify remove user request',
  VerifyRemoveUserSuccess = '[USER] Verify remove user success',
  VerifyRemoveUserError = '[USER] Verify remove user error',

  InvitationAddNewUserRequest = '[USER] Invitation add new user request',
  InvitationAddNewUserSuccess = '[USER] Invitation add new user success',
  InvitationAddNewUserError = '[USER] Invitation add new user error',

  CheckExistenceUserRequest = '[USER] Check existence user request',
  CheckExistenceUserSuccess = '[USER] Check existence user success',
  CheckExistenceUserError = '[USER] Check existence user error',

  UpdateUserRequest = '[USER] Update user request',
  UpdateUserSuccess = '[USER] Update user success',
  UpdateUserError = '[USER] Update user error',

  UpdatePermissionsRequest = '[USER] Update user permissions request',
  UpdatePermissionsSuccess = '[USER] Update user permissions success',
  UpdatePermissionsError = '[USER] Update user permissions error',

  ClientIdsForActiveUsersRequest = '[USER] Client ids for active users request',
  ClientIdsForActiveUsersSuccess = '[USER] Client ids for active users success',
  ClientIdsForActiveUsersError = '[USER] Client ids for active users error',

  SetSelectedUser = '[USER] Set selected user',
  ClearOnQuit = '[USER] Clear on quit',
  ResetUsersValue = '[USER] reset users value',
  UpdateAvatar = '[USER] update user avatar',
  UpdateAvatarSuccess = '[USER] update user avatar success',
  UpdateAvatarError = '[USER] update user avatar error',
}

export class ClearOnQuit implements Action {
  readonly type = UserActionsTypes.ClearOnQuit;
}

export class SetUsersTotalCount implements Action {
  readonly type = UserActionsTypes.UsersTotalCount;

  constructor(public payload: { totalCount: number }) {}
}

export class UsersLoadPageRequest implements Action {
  readonly type = UserActionsTypes.UsersLoadPageRequest;

  constructor(public body: { page: number; itemsPerPage: number }) {}
}

export class UsersLoadPageRequestFailure implements Action {
  readonly type = UserActionsTypes.UsersLoadPageRequestFailure;

  constructor(public error?: any) {}
}

export class UsersLoadRequest implements Action {
  readonly type = UserActionsTypes.UsersLoadRequest;

  constructor(public payload: GetUserCriteria) {}
}

export class UsersLoadSuccess implements Action {
  readonly type = UserActionsTypes.UsersLoadSuccess;

  constructor(public payload: { users: User[]; totalPages: number; currentPage: number }) {}
}

export class UsersLoadError implements Action {
  readonly type = UserActionsTypes.UsersLoadError;

  constructor(public error: { errors: any[] | null }) {}
}

export class AddUserRequest implements Action {
  readonly type = UserActionsTypes.AddUserRequest;

  constructor(public payload: PostUser) {}
}

export class AddUserSuccess implements Action {
  readonly type = UserActionsTypes.AddUserSuccess;

  constructor(public payload: { user: User }) {}
}

export class AddUserError implements Action {
  readonly type = UserActionsTypes.AddUserError;

  constructor(public error: { errors: any[] | null }) {}
}

export class SendSmsRemoveUserRequest implements Action {
  readonly type = UserActionsTypes.SendSmsRemoveUserRequest;

  constructor(public payload: DeleteUserCriteria) {}
}

export class SendSmsRemoveUserSuccess implements Action {
  readonly type = UserActionsTypes.SendSmsRemoveUserSuccess;
}

export class SendSmsRemoveUserError implements Action {
  readonly type = UserActionsTypes.SendSmsRemoveUserError;

  constructor(public error: { errors: any[] | null }) {}
}

export class VerifyAddNewUserRequest implements Action {
  readonly type = UserActionsTypes.VerifyAddNewUserRequest;

  constructor(public payload: PostVerifyUser) {}
}

export class VerifyAddNewUserSuccess implements Action {
  readonly type = UserActionsTypes.VerifyAddNewUserSuccess;
}

export class VerifyAddNewUserError implements Action {
  readonly type = UserActionsTypes.VerifyAddNewUserError;

  constructor(public error: { errors: any[] | null }) {}
}

export class VerifyRemoveUserRequest implements Action {
  readonly type = UserActionsTypes.VerifyRemoveUserRequest;

  constructor(public payload: PostVerifyUser) {}
}

export class VerifyRemoveUserSuccess implements Action {
  readonly type = UserActionsTypes.VerifyRemoveUserSuccess;
}

export class VerifyRemoveUserError implements Action {
  readonly type = UserActionsTypes.VerifyRemoveUserError;

  constructor(public error: { errors: any[] | null }) {}
}

export class InvitationAddNewUserRequest implements Action {
  readonly type = UserActionsTypes.InvitationAddNewUserRequest;

  constructor(public payload: PostSmsUser) {}
}

export class InvitationAddNewUserSuccess implements Action {
  readonly type = UserActionsTypes.InvitationAddNewUserSuccess;
}

export class InvitationAddNewUserError implements Action {
  readonly type = UserActionsTypes.InvitationAddNewUserError;

  constructor(public error: { errors: any[] | null }) {}
}

export class CheckExistenceUserRequest implements Action {
  readonly type = UserActionsTypes.CheckExistenceUserRequest;

  constructor(public payload: { phoneNumber: string }) {}
}

export class CheckExistenceUserSuccess implements Action {
  readonly type = UserActionsTypes.CheckExistenceUserSuccess;

  constructor(public payload: { canBeAuthorized: boolean }) {}
}

export class CheckExistenceUserError implements Action {
  readonly type = UserActionsTypes.CheckExistenceUserError;

  constructor(public error: { errors: any[] | null }) {}
}

export class UpdateUserRequest implements Action {
  readonly type = UserActionsTypes.UpdateUserRequest;

  constructor(public payload: { userId: number; data: PostUpdateUser }) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionsTypes.UpdateUserSuccess;

  constructor(public payload: { user: User }) {}
}

export class UpdateUserError implements Action {
  readonly type = UserActionsTypes.UpdateUserError;

  constructor(public error: { errors: any[] | null }) {}
}

export class UpdatePermissionsRequest implements Action {
  readonly type = UserActionsTypes.UpdatePermissionsRequest;

  constructor(public payload: { userId: number; data: PostUserPermissions }) {}
}

export class UpdatePermissionsSuccess implements Action {
  readonly type = UserActionsTypes.UpdatePermissionsSuccess;

  constructor(public payload: { user: User }) {}
}

export class UpdatePermissionsError implements Action {
  readonly type = UserActionsTypes.UpdatePermissionsError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ClientIdsForActiveUsersRequest implements Action {
  readonly type = UserActionsTypes.ClientIdsForActiveUsersRequest;
}

export class ClientIdsForActiveUsersSuccess implements Action {
  readonly type = UserActionsTypes.ClientIdsForActiveUsersSuccess;

  constructor(public payload: { clientIds: number[] }) {}
}

export class SetSelectedUser implements Action {
  readonly type = UserActionsTypes.SetSelectedUser;

  constructor(public payload: { selectedUserId: number }) {}
}

export class ClientIdsForActiveUsersError implements Action {
  readonly type = UserActionsTypes.ClientIdsForActiveUsersError;

  constructor(public error: { errors: any[] | null }) {}
}

export class ResetUsersValue implements Action {
  readonly type = UserActionsTypes.ResetUsersValue;
}

export class UpdateAvatar implements Action {
  readonly type = UserActionsTypes.UpdateAvatar;
  constructor(public payload: { file: File }) {}
}

export class UpdateAvatarSuccess implements Action {
  readonly type = UserActionsTypes.UpdateAvatarSuccess;
  constructor(public payload: { content: Content }) {}
}

export class UpdateAvatarError implements Action {
  readonly type = UserActionsTypes.UpdateAvatarError;
  constructor(public error: { errors: any[] | null }) {}
}

export type UserActionsUnion =
  | UsersLoadRequest
  | SetUsersTotalCount
  | UsersLoadSuccess
  | UsersLoadError
  | AddUserRequest
  | AddUserSuccess
  | AddUserError
  | SendSmsRemoveUserRequest
  | SendSmsRemoveUserSuccess
  | SendSmsRemoveUserError
  | VerifyAddNewUserRequest
  | VerifyAddNewUserSuccess
  | VerifyAddNewUserError
  | VerifyRemoveUserRequest
  | VerifyRemoveUserSuccess
  | VerifyRemoveUserError
  | InvitationAddNewUserRequest
  | InvitationAddNewUserSuccess
  | InvitationAddNewUserError
  | CheckExistenceUserRequest
  | CheckExistenceUserSuccess
  | CheckExistenceUserError
  | UpdateUserRequest
  | UpdateUserSuccess
  | UpdateUserError
  | UpdatePermissionsRequest
  | UpdatePermissionsSuccess
  | UpdatePermissionsError
  | ClientIdsForActiveUsersRequest
  | ClientIdsForActiveUsersSuccess
  | ClientIdsForActiveUsersError
  | SetSelectedUser
  | ClearOnQuit
  | ResetUsersValue
  | UsersLoadPageRequest
  | UsersLoadPageRequestFailure
  | UpdateAvatar
  | UpdateAvatarSuccess
  | UpdateAvatarError;
