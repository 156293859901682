import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { API_USER_URL } from '@Env/environment';
import { IUserInfo, IUserInfoResponse, User } from '@Mesh/core/models/user';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { Authorized, Login, RegConfirm, RegisterPhone, Entity } from '@Mesh/core/models/login';
import {
  Participant,
  ParticipantRequestPost,
  ParticipantRequestPostConfirm,
  ParticipantResponsePostConfirm,
  ParticipantRequestGet,
} from '@Mesh/core/models/participant';
import { PageableV2List } from '@Mesh/core/models/pageable-v2';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { PostApplication } from '@Mesh/core/models/request/post-application';
import { PostRegVerify, PostRequestSignUp } from '@Mesh/core/models/request/post-auth';
import { SetPasswordRequestBody, VerifyUpdatePasswordRequestBody } from '@Mesh/core/models/request/password-models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _currentUserToken: BehaviorSubject<string>;
  private _currentUser: User;
  private userRegisterInfo = {
    code: '',
    identifier: null,
    phoneNumber: '',
  };

  constructor(private http: HttpClient, private localStorage: LocalStorageService, private store: Store<AppState>) {}

  get currentUser(): User {
    return this._currentUser;
  }

  get currentUserToken(): BehaviorSubject<string> {
    return this._currentUserToken;
  }

  get userRegisterInfoValue() {
    return this.userRegisterInfo;
  }

  set setUserRegisterInfo(value) {
    this.userRegisterInfo.identifier = value.identifier;
    this.userRegisterInfo.phoneNumber = value.phoneNumber;
    this.userRegisterInfo.code = value.code;
  }

  set currentUser(value: User) {
    this._currentUser = value;
  }

  get token(): string {
    return this.localStorage.getItem('token');
  }

  login(user: Login): Observable<IUserInfo> {
    const params = {
      phone: '7' + user.phone,
      password: user.password,
    };
    return this.http.post<IUserInfo>(`${API_USER_URL}/token`, params).pipe(map((response: any) => this.mapUserInfo(response)));
  }

  refreshToken(refreshToken: string): Observable<IUserInfo> {
    return this.http
      .post<IUserInfo>(`${API_USER_URL}/token/refresh-token`, { refreshToken })
      .pipe(map((response: any) => this.mapUserInfo(response)));
  }

  getLegalEntities(): Observable<Array<Entity>> {
    return this.http.get<any>(`${API_USER_URL}/user/client`, {});
  }

  checkExistPhoneNumber(phoneNumber: string): Observable<Authorized> {
    return this.http.post<Authorized>(`${API_USER_URL}/user/check/pre-authorization/${phoneNumber}`, {});
  }

  registerPhoneNumber(phoneNumber: string): Observable<RegisterPhone> {
    return this.http.get<RegisterPhone>(`${API_USER_URL}/registration`, { params: { phoneNumber } });
  }

  registerPhoneNumberConfirm(identifier: number): Observable<RegConfirm> {
    return this.http.post<RegConfirm>(`${API_USER_URL}/registration/confirm`, { identifier: identifier.toString() });
  }

  acceptCode(obj: PostRegVerify) {
    return this.http.post<any>(`${API_USER_URL}/registration/verify`, { ...obj });
  }

  signup(obj: PostRequestSignUp): Observable<IUserInfo> {
    return this.http
      .post<IUserInfoResponse>(`${API_USER_URL}/registration/signup`, { ...obj })
      .pipe(map((response) => this.mapUserInfo(response)));
  }

  application(obj: PostApplication): Observable<void> {
    return this.http.post<void>(`${API_USER_URL}/client/application`, { ...obj });
  }

  logout(): void {
    if (window.location.href.indexOf('auth/login') === -1) {
      window.location.replace('/auth/login');
    }

    this.localStorage.removeAll();
  }

  updatePasswordRequest(phoneNumber: string): Observable<{ identifier: number }> {
    return this.http.post<{ identifier: number }>(`${API_USER_URL}/password`, { phoneNumber });
  }

  updatePasswordVerify(body: VerifyUpdatePasswordRequestBody): Observable<void> {
    return this.http.post<void>(`${API_USER_URL}/password/verify`, body);
  }

  setNewPassword(body: SetPasswordRequestBody): Observable<IUserInfo> {
    return this.http.post<IUserInfoResponse>(`${API_USER_URL}/password/set`, body).pipe(map((response) => this.mapUserInfo(response)));
  }

  private mapUserInfo(response: any): IUserInfo {
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      accessTokenExpires: response.accessTokenExpires,
      refreshTokenExpires: response.refreshTokenExpires,
      user: response.user,
    } as IUserInfo;
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<User>(`${API_USER_URL}/user/${userId}`);
  }

  setClient(clientId: number): Observable<IUserInfo> {
    return this.http
      .post<IUserInfo>(`${API_USER_URL}/user/client/change`, { clientId: clientId })
      .pipe(map((response: any) => this.mapUserInfo(response)));
  }

  getParticipant(body: ParticipantRequestGet): Observable<PageableV2List<Participant>> {
    return this.http.get<PageableV2List<Participant>>(`${API_USER_URL}/participant`);
  }

  createParticipant(body: ParticipantRequestPost): Observable<Participant> {
    return this.http.post<Participant>(`${API_USER_URL}/participant`, body);
  }

  sendSmsCodeParticipant(body: ParticipantRequestPostConfirm): Observable<ParticipantResponsePostConfirm> {
    return this.http.post<ParticipantResponsePostConfirm>(`${API_USER_URL}/participant/confirm`, body);
  }
}
