import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { switchMap, map, catchError, tap, take, filter } from 'rxjs/operators';
import { UserSchool } from '../models/user-school';
import { loginSelectors } from '../../store/login/login.selectors';
import { API_SCHOOL_URL } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserSchoolService {
  private userSubject = new BehaviorSubject<UserSchool | null>(null);
  user$: Observable<UserSchool | null> = this.userSubject.asObservable();
  private isUserLoaded = false;

  constructor(private http: HttpClient, private store: Store<any>) {
    this.initializeUser();
  }

  private initializeUser(): void {
    this.store
      .pipe(
        select(loginSelectors.selectIsAuthenticated),
        filter((isAuthenticated) => isAuthenticated),
        take(1)
      )
      .subscribe(() => {
        if (!this.isUserLoaded) {
          this.loadCurrentUser();
          this.isUserLoaded = true;
        }
      });
  }

  private loadCurrentUser(): void {
    this.http
      .get<{ data: UserSchool }>(`${API_SCHOOL_URL}/user`)
      .pipe(
        map((res) => res.data),
        tap((user) => this.userSubject.next(user)),
        catchError((err) => {
          console.error('Error fetching user data:', err);
          this.userSubject.next(null);
          return [];
        })
      )
      .subscribe();
  }

  updateUser(user: UserSchool): void {
    this.userSubject.next(user);
  }

  getCurrentUser(): UserSchool | null {
    return this.userSubject.value;
  }
}
