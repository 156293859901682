import { Outlet } from '@Mesh/core/models';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  OutletLoadError,
  OutletLoadSuccess,
  OutletActionsTypes,
  OutletLoadRequest,
  OutletSetActive,
  OutletSetActiveSuccess,
  OutletSetStatistic,
  OutletSetStatisticSuccess,
  OutletLoadPageRequest,
  OutletLoadPageFailure,
  ResetOutletsValue,
} from './outlet.actions';
import { Action, Store } from '@ngrx/store';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { AppState } from '@Mesh/store/app.state';
import { TaskActionsTypes, TaskLoadRequest, TaskLoadSuccess } from '@Mesh/store/task/task.actions';
import { taskSelectors } from '@Mesh/store/task/task.selectors';
import { OutletService } from '@Mesh/core/services/outlet.service';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { OutletStatistic } from '@Mesh/core/models';
import { GetOutletCriteria } from '@Mesh/core/models/request';
import { LoginActionsTypes, LogoutRequest } from '@Mesh/store/login/login.actions';

@Injectable()
export class OutletEffects {
  constructor(private actions$: Actions, private store$: Store<AppState>, private outletService: OutletService) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<OutletLoadRequest>(OutletActionsTypes.OutletLoadRequest),
    switchMap((action) =>
      this.store$.select(loginSelectors.selectUser).pipe(
        filter((user) => !!user),
        mergeMap((user) => this.outletService.getOutlets({ ...action.payload, clientId: user.client.id })),
        withLatestFrom(this.store$.select(outletSelectors.getActive)),
        mergeMap(([res, current]) => {
          const currentOutlet = current || res[0];
          return [
            new OutletLoadSuccess({ outlet: res, totalPage: 1, totalCount: res.length }),
            new OutletSetActive({ active: currentOutlet }),
          ];
        })
      )
    ),
    catchError((error) => of(new OutletLoadError(error)))
  );

  @Effect()
  loadOutletPage: Observable<Action> = this.actions$.pipe(
    ofType<OutletLoadPageRequest>(OutletActionsTypes.OutletLoadPageRequest),
    withLatestFrom(this.store$.select(outletSelectors.getOutlets)),
    switchMap(([action, outlets]) =>
      this.store$.select(loginSelectors.selectUser).pipe(
        filter((user) => !!user),
        mergeMap((user) => {
          const outletsCriteria: Partial<GetOutletCriteria> = {};
          return this.outletService.getOutlets({ ...outletsCriteria, clientId: user.client.id });
        }),
        withLatestFrom(this.store$.select(outletSelectors.getActive)),
        mergeMap(([res, current]) => {
          let activeOutlet;
          if (current && res.length) {
            current = activeOutlet = res.find((item) => {
              return item.addressId === current.addressId;
            });
          }
          const currentOutlet = current || res[0];
          const newOutletsArr = [...outlets, ...res] as Outlet[];
          return [
            new OutletLoadSuccess({ outlet: newOutletsArr, totalPage: 1, totalCount: res.length }),
            new OutletSetActive({ active: currentOutlet }),
          ];
        })
      )
    ),
    catchError((error) => of(new OutletLoadPageFailure(error)))
  );

  @Effect()
  setActive: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive>(OutletActionsTypes.OutletSetActive),
    map((action) => new OutletSetActiveSuccess()),
    catchError((error) => of(new OutletLoadError(error)))
  );

  // @Effect()
  // setActiveSuccess: Observable<Action> = this.actions$.pipe(
  //   ofType<OutletSetActiveSuccess>(OutletActionsTypes.OutletSetActiveSuccess),
  //   map(() => new TaskLoadRequest({pageNumber: 0, pageSize: 3, completed: false})),
  //   catchError(error => of(new OutletLoadError(error)))
  // );

  @Effect()
  setStatistic: Observable<Action> = this.actions$.pipe(
    ofType<TaskLoadSuccess>(TaskActionsTypes.TaskLoadSuccess),
    switchMap((action) =>
      this.store$.select(taskSelectors.getActiveTasksTasks).pipe(
        take(1),
        map((tasksList) => {
          {
            if (!tasksList) {
              return new OutletSetStatistic({ statistic: null });
            }
            const status: OutletStatistic = {
              moderation: 0,
              total: 0,
              success: 0,
              cost: 0,
            };
            /* tasksList.content.forEach( task => {
            if (task.taskStatus.inModeration) {
              status.moderation++;
            }
            if (task.taskStatus.isSuccess) {
              status.cost += task.cost;
              status.success++;
            }
            status.total++;
          });*/

            return new OutletSetStatistic({ statistic: status });
          }
        })
      )
    ),
    catchError((error) => of(new OutletLoadError(error)))
  );

  @Effect()
  setStatisticSuccess: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetStatistic>(OutletActionsTypes.OutletSetStatistic),
    map(() => new OutletSetStatisticSuccess()),
    catchError((error) => of(new OutletLoadError(error)))
  );

  @Effect()
  logout = this.actions$.pipe(
    ofType<LogoutRequest>(LoginActionsTypes.Logout),
    map(() => new ResetOutletsValue()),
    catchError((error) => of(new OutletLoadError(error)))
  );
}
