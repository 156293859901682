import { Deserializable } from './deserializable';

export class Answer implements Deserializable {
  id?: number;
  answer?: string;
  answer_equal?: string;
  image?: string;
  image_equal?: string;
  image_id?: number;
  image_equal_id?: number;
  is_video?: boolean;
  time?: number;
  selected?: boolean;
  is_correct?: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
