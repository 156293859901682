import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { User } from '@Mesh/core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AddUserRequest,
  ClearOnQuit,
  SendSmsRemoveUserRequest,
  SetSelectedUser,
  UpdatePermissionsRequest,
  UpdateUserRequest,
  VerifyAddNewUserRequest,
  VerifyRemoveUserRequest,
} from '@Mesh/store/user/user.actions';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { userSelectors } from '@Mesh/store/user/user.selectors';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { POSTRequestStatus } from '@Mesh/store/user/user.state';
import { Subject } from 'rxjs/Subject';
import { PostUpdateUser, PostUserPermissions } from '@Mesh/core/models/request';
import { scrollToTopHelper } from '@Mesh/shared/helpers/scrollToTop.helper';
import { ResizeHelper } from '@Mesh/shared/helpers/resize.helper';
import { BehaviorSubject } from 'rxjs';
import { Documents } from '@Mesh/core/models/documents';
import { documentSelectors } from '@Mesh/store/document/document.selectors';
import { DocumentSignedRequest, DocumentSignRequest } from '@Mesh/store/document/document.actions';
import { RequestUserUpdatePassword, VerifyUpdatePassword } from '@Mesh/store/login/login.actions';

const permissionsMap = {
  addUsers: 'ADD_USER',
  addBoss: 'ADDR_BOSS',
};

@Component({
  selector: 'app-user-dialogs',
  templateUrl: './user-dialogs.component.html',
  styleUrls: ['./user-dialogs.component.scss'],
  animations: [
    trigger('openDocument', [
      state(
        'open',
        style({
          opacity: '*',
          zIndex: '1',
          right: 0,
          width: '650px',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          zIndex: '-1 ',
          right: '0',
          width: 0,
        })
      ),
      transition('open => closed', [animate('0.2s')]),
      transition('closed => open', [animate('0.2s')]),
    ]),
    trigger('openDocumentMobile', [
      state(
        'open',
        style({
          opacity: '*',
          zIndex: '900',
          right: 0,
          width: '100vw',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          zIndex: '-1 ',
          right: '',
          width: 0,
        })
      ),
      transition('open => closed', [animate('0.2s')]),
      transition('closed => open', [animate('0.2s')]),
    ]),
    trigger('doOpacity', [
      state(
        'open',
        style({
          opacity: '.7',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
        })
      ),
      transition('open => closed', [animate('0.2s')]),
      transition('closed => open', [animate('0.2s', style({ opacity: '.4' }))]),
    ]),
    trigger('add-employee', [
      state(
        'open',
        style({
          opacity: '*',
          zIndex: '10001',
          top: 'calc(50% - 340px)',
          height: 'fit-content',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          zIndex: '-1',
          top: 0,
          height: 0,
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
    trigger('update-pass', [
      state(
        'open',
        style({
          opacity: '*',
          zIndex: '10001',
          top: 'calc(50% - 225px)',
          height: '450px',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          zIndex: '-1',
          top: '100vh',
          height: 0,
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
    trigger('add-employee-header', [
      state(
        'open',
        style({
          opacity: '*',
        })
      ),
      state(
        'closed',
        style({
          top: 0,
          opacity: 0,
        })
      ),
      transition('open => closed', [animate('.5s')]),
      transition('closed => open', [animate('.5s')]),
    ]),
  ],
})
export class UserDialogsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() readonly userDialogData: { show: boolean; selectedUserId: number };
  @Input() openView:
    | 'addEmployee'
    | 'sms-verification'
    | 'sms-verified'
    | 'employee-deleted-verified'
    | 'employee-delete'
    | 'update-pass'
    | 'none' = 'none';
  @Input() subscribeButtonToggle = false;
  @Input() subscribeDocumentToggle = false;
  @Input() deleteUserData?: number;
  @Input() readonly iframeSrc: string;
  @Input() readonly selectedDoc: Documents;
  @Input() readonly lastAction: Action;

  @Output() changeOpenView = new EventEmitter<
    'addEmployee' | 'sms-verification' | 'sms-verified' | 'employee-deleted-verified' | 'employee-delete' | 'update-pass' | 'none'
  >();
  @Output() subscribeDocument = new EventEmitter<boolean>();
  @Output() downloadClick = new EventEmitter();
  @Output() setLastAction = new EventEmitter<Action>();

  employeeHeaderSectionToggle = false;
  profileForm: FormGroup;
  changeInfo = false;
  selectedUser: User;
  updatePassForm: FormGroup;
  countDownConfig: { leftTime: number; template: string };
  clientId: number;
  addressId: number;
  verifyCode: string;

  previousPermissions = {
    manager: false,
    addUsers: false,
    addBoss: false,
  };

  countdownDone = false;
  signSMSVerified = false;

  incorrectCode: boolean;
  newUserPass: any;
  isManagerDisabled: boolean;

  readonly activeOutlet$ = this.store.select(outletSelectors.getActive);
  currentUser$ = this.store.select(loginSelectors.selectUser);
  readonly smsReason$ = new BehaviorSubject<'create' | 'delete' | 'sign' | 'updatePassword'>(undefined);

  private unsubscribe$ = new Subject();

  get controls() {
    return this.profileForm.controls;
  }

  constructor(private fb: FormBuilder, private store: Store<AppState>, private cdr: ChangeDetectorRef, public resize: ResizeHelper) {}

  ngOnInit() {
    this.profileForm = this.fb.group({
      phone: ['', Validators.required],
      name: ['', Validators.required],
      position: ['', Validators.required],
      type: ['', Validators.required],
    });
    this.updatePassForm = this.fb.group({
      currentPass: ['', Validators.required],
      newPass: ['', Validators.required],
      confirmNewPass: ['', Validators.required],
    });

    this.store
      .select(userSelectors.newUser)
      .pipe(
        filter((user) => user !== null && user !== undefined),
        map((user) => {
          this.profileForm.reset();
          this.changeOpenView.emit('sms-verification');
          this.countDownConfig = { leftTime: 60, template: '$!s!' };
          this.selectedUser = user;
          this.smsReason$.next('create');
          return user;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .select(userSelectors.user)
      .pipe(
        filter((user) => user !== null && user !== undefined),
        map((user) => {
          this.selectedUser = user;
          return user;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .select(userSelectors.statusVerifyAddUser)
      .pipe(
        filter((status) => status === POSTRequestStatus.SUCCESS),
        map((s) => {
          this.onShowSMSVerified(true);
          return s;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .select(userSelectors.statusVerifyRemoveUser)
      .pipe(
        filter((status) => status === POSTRequestStatus.SUCCESS),
        map((s) => {
          this.onShowSMSVerified(true);
          return s;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .select(userSelectors.statusUpdateUser)
      .pipe(
        filter((status) => status === POSTRequestStatus.SUCCESS),
        map((s) => {
          this.profileForm.reset();
          this.changeInfo = false;
          this.onShowSMSVerified(true);
          return s;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .select(userSelectors.statusSendSmsRemoveUser)
      .pipe(
        filter((status) => status === POSTRequestStatus.SUCCESS),
        map((s) => {
          this.changeOpenView.emit('sms-verification');
          this.countDownConfig = { leftTime: 60, template: '$!s!' };
          return s;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .pipe(
        select(loginSelectors.selectUser),
        switchMap((user) => {
          this.clientId = user.client.id;
          return this.store.select(outletSelectors.getActive).pipe(
            filter((outlet) => !!outlet),
            tap((outlet) => {
              this.addressId = outlet.id;
            })
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .select(documentSelectors.getSentSMSStatus)
      .pipe(
        filter((status) => status === POSTRequestStatus.SENT),
        map((s) => {
          this.signSMSVerified = true;
          this.changeOpenView.emit('sms-verification');
          this.countDownConfig = { leftTime: 60, template: '$!s!' };
          return s;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.store
      .select(documentSelectors.getSentSignedStatus)
      .pipe(
        map((status) => {
          if (status === POSTRequestStatus.SUCCESS) {
            this.signSMSVerified = false;
            this.onShowSMSVerified(true);
            this.subscribeDocument.emit(false);
            return status;
          } else {
            this.incorrectCode = status === POSTRequestStatus.FAILURE;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnChanges({ userDialogData, deleteUserData }: SimpleChanges) {
    if (userDialogData && userDialogData.currentValue) {
      const data = userDialogData.currentValue as { show: boolean; selectedUserId: number };
      this.onShowAddEmployee(data.show, data.selectedUserId);
    }
    if (deleteUserData && deleteUserData.currentValue) {
      this.showEmployeeDeleteSection(deleteUserData.currentValue);
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearOnQuit());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addEmployeeAnimationDone(e): void {
    this.employeeHeaderSectionToggle = e.toState === 'open';
  }

  onSubmit(): void {
    if (this.changeInfo) {
      const data = {
        name: this.profileForm.value.name,
        position: this.profileForm.value.position,
      } as PostUpdateUser;
      this.store.dispatch(new UpdateUserRequest({ userId: this.selectedUser.id, data }));

      if (JSON.stringify(this.previousPermissions) !== JSON.stringify(this.transformPermissionsToObject())) {
        this.activeOutlet$
          .pipe(
            filter((el) => !!el),
            take(1)
          )
          .subscribe((activeOutlet) => {
            const permData: PostUserPermissions = {
              manager: this.controls['type'].value === 'manager',
              permissions: [...this.selectedUser.permissions],
            };
            const perms = permData.permissions.find((el) => el.addressId === activeOutlet.id);
            if (perms) {
              const permObj = {
                addUsers: this.controls['type'].value === 'addUsers',
                addBoss: this.controls['type'].value === 'addBoss',
              };
              perms.permissions = Object.entries(permObj)
                .filter(([, value]) => !!value)
                .map(([key]) => permissionsMap[key]);
              this.store.dispatch(new UpdatePermissionsRequest({ userId: this.selectedUser.id, data: permData }));
            }
          });
      }
    } else if (this.profileForm.valid) {
      const body = {
        clientId: this.clientId,
        name: this.profileForm.value.name,
        phone: '7' + this.profileForm.value.phone,
        position: this.profileForm.value.position,
        permissions: [],
        manager: this.controls['type'].value === 'manager',
        confirmationPhone: '7' + this.profileForm.value.phone,
      };
      if (!body.manager) {
        const p = {
          addressId: this.addressId,
          permissions: [],
        };
        if (this.profileForm.value.addUsers) {
          p.permissions.push('ADD_USER');
        }
        if (this.profileForm.value.addBoss) {
          p.permissions.push('ADDR_BOSS');
        }
        body.permissions.push(p);
      }
      this.store.dispatch(new AddUserRequest(body));
    }
  }

  onShowAddEmployee(show: boolean, selectedUserId = -1): void {
    this.changeInfo = selectedUserId !== -1;
    if (selectedUserId !== -1) {
      this.store.dispatch(new SetSelectedUser({ selectedUserId }));
      this.profileForm.patchValue(this.selectedUser);
      this.setPermissions();
    } else {
      this.profileForm.reset();
    }
    this.changeOpenView.emit(show ? 'addEmployee' : 'none');
  }

  showEmployeeDeleteSection(selectedUserId: number): void {
    this.store.dispatch(new SetSelectedUser({ selectedUserId }));
    this.changeOpenView.emit('employee-delete');
    this.profileForm.reset();
    this.changeInfo = false;
  }

  onShowSMSVerified(isShow: boolean): void {
    this.changeOpenView.emit(isShow ? 'sms-verified' : 'none');
    if (isShow) {
      setTimeout(() => this.onShowSMSVerified(false), 1500);
    }
    this.cdr.detectChanges();
  }

  onUpdatePassSubmit(): void {
    const emptyPassword = this.updatePassForm.value.newPass === '' && this.updatePassForm.value.confirmNewPass === '';
    const validPassword = this.updatePassForm.value.newPass === this.updatePassForm.value.confirmNewPass && !emptyPassword;
    if (validPassword) {
      this.newUserPass = this.updatePassForm.value.newPass;
    }
    let currentUser: User = null;
    this.currentUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => (currentUser = res));
    if (currentUser && validPassword) {
      this.store.dispatch(new RequestUserUpdatePassword({ phoneNumber: currentUser.phone, password: this.newUserPass }));
      this.openView = 'sms-verification';
      this.smsReason$.next('updatePassword');
      this.updatePassForm.reset();
    } else if (!currentUser) {
      this.changeOpenView.emit('none');
    }
    this.cdr.detectChanges();
  }

  onSMSVerification(isConfirm: boolean): void {
    this.smsReason$.pipe(take(1)).subscribe((reason) => {
      this.countDownConfig = null;
      if (isConfirm) {
        if (reason === 'create' && this.verifyCode?.length === 6) {
          this.store.dispatch(
            new VerifyAddNewUserRequest({
              code: this.verifyCode,
              identifier: '' + this.selectedUser?.id,
            })
          );
        } else if (reason === 'delete' && this.verifyCode?.length === 6) {
          this.store.dispatch(
            new VerifyRemoveUserRequest({
              code: this.verifyCode,
              identifier: '' + this.selectedUser?.id,
            })
          );
        } else if (reason === 'sign' && this.verifyCode?.length === 6) {
          this.store.dispatch(
            new DocumentSignedRequest({
              id: this.selectedDoc.id,
              body: {
                code: this.verifyCode,
              },
            })
          );
        } else if (reason === 'updatePassword' && this.verifyCode?.length === 6) {
          this.store.dispatch(
            new VerifyUpdatePassword({
              code: this.verifyCode,
            })
          );
          this.onShowSMSVerified(true);
        }
        scrollToTopHelper();
      } else {
        this.changeOpenView.emit('none');
      }
    });
  }

  deleteEmployee(isDelete: boolean): void {
    if (isDelete) {
      const action = new SendSmsRemoveUserRequest({
        userId: this.selectedUser.id,
        confirmationPhone: this.selectedUser.phone,
      });
      this.store.dispatch(action);
      this.setLastAction.emit(action);
      this.smsReason$.next('delete');
    } else {
      this.changeOpenView.emit('none');
    }
  }

  handleCountDownEvent($event: { action: string; left: number }) {
    if ($event.action === 'finished') {
      this.countdownDone = true;
    } else if ($event.action === 'start') {
      this.countdownDone = false;
    }
  }

  sendCodeAgain() {
    this.countdownDone = false;
    this.countDownConfig = { leftTime: 60, template: '$!s!' };
    if (this.lastAction) {
      this.store.dispatch(this.lastAction);
    }
  }

  onSignDoc(): void {
    if (this.subscribeDocumentToggle) {
      this.smsReason$.next('sign');
      const action = new DocumentSignRequest({
        id: this.selectedDoc.id,
      });
      this.store.dispatch(action);
      this.setLastAction.emit(action);
    }
  }

  private setPermissions() {
    if (this.selectedUser.type === 'MANAGER') {
      this.profileForm.patchValue({ type: 'manager' });
    } else if (this.selectedUser.type === 'OWNER') {
      this.profileForm.patchValue({ type: 'addBoss' });
    } else if (this.selectedUser.type === 'EMPLOYEE') {
      this.profileForm.patchValue({ type: 'addUsers' });
    }

    this.activeOutlet$
      .pipe(
        filter((activeOutlet) => !!activeOutlet),
        take(1)
      )
      .subscribe((activeOutlet) => {
        const addressPermissions = this.selectedUser?.permissions.find((el) => {
          return el.addressId === activeOutlet.id;
        });
        if (addressPermissions) {
          if (addressPermissions.permissions.includes('ADDR_BOSS')) {
            this.profileForm.patchValue({ type: 'addBoss' });
          }
          if (addressPermissions.permissions.includes('ADD_USER')) {
            this.profileForm.patchValue({ type: 'addUsers' });
          }
        }

        this.previousPermissions = this.transformPermissionsToObject();
      });
  }

  private transformPermissionsToObject() {
    return {
      addUsers: this.controls['type'].value === 'addUsers',
      addBoss: this.controls['type'].value === 'addBoss',
      manager: this.controls['type'].value === 'manager',
    };
  }
}
