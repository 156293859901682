import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { orderSelectors } from '@Mesh/store/order/order.selectors';
import { taskSelectors } from '@Mesh/store/task/task.selectors';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Outlet, User } from '@Mesh/core/models';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { financeSelectors } from '@Mesh/store/finance/finance.selectors';
import { UserHistoryBonus } from '@Mesh/core/models/user-bonus-history';
import { ClientHistoryBonus } from '@Mesh/core/models/client-bonus-history';
import { GetClientHistoryCriteria } from '@Mesh/core/models/request/get-client-bonus-history';
import { filter, take, takeUntil } from 'rxjs/operators';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { GetUserHistoryCriteria } from '@Mesh/core/models/request/get-user-bonus-history';
import { BonusService } from '@Mesh/core/services/bonus.service';
import { GetDelayedOrders, OrderHistoryLoadPageRequest, OrderHistoryLoadRequest, OrderSetActive } from '@Mesh/store/order/order.actions';
import { getGetStartDateAndFinishDate } from '@Mesh/core/services/util';
import { HistoryTaskLoadPageRequest, HistoryTaskLoadRequest } from '@Mesh/store/task/task.actions';
import { LoadPayHistory, LoadPayHistoryPage } from '@Mesh/store/finance/finance.actions';
import { Router } from '@angular/router';
import { OrderStatus } from '../../history.models';

@Component({
  selector: 'app-history-widget',
  templateUrl: './history-widget.component.html',
  styleUrls: ['./history-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryWidgetComponent implements OnInit, OnDestroy {
  user: User;
  user$ = this.store.select(loginSelectors.selectUser);
  historyOrders$ = this.store.select(orderSelectors.getHistoryOrders);
  historyTasks$ = this.store.select(taskSelectors.getHistoryTasks);
  activeOutlet$: Observable<Outlet> = this.store.select(outletSelectors.getActive);
  details$ = this.store.select(orderSelectors.getDetailedOrder);
  totalHistoryOrders$ = this.store.select(orderSelectors.getHistoryTotalCount);
  payHistory$ = this.store.select(financeSelectors.getPayHistory);
  balance$ = this.store.select(financeSelectors.getBalance);
  selectedOrder$ = this.store.select(orderSelectors.getSelectedId);
  totalActiveOrdersPages$ = this.store.select(orderSelectors.getOrderTotalPages);
  totalTasksPages$ = this.store.select(taskSelectors.getTotalPages);
  getHistoryOrdersCurrentPage$ = this.store.select(orderSelectors.getHistoryOrdersCurrentPage);
  getHistoryTasksCurrentPage$ = this.store.select(taskSelectors.historyTasksCurrentPage);

  userBonusesHistory: UserHistoryBonus[] = [];
  clientBonusesHistory: ClientHistoryBonus[] = [];

  loadingBonusHistoryClient: boolean;
  loadingBonusHistory: boolean;
  pageToLoadNextBonuses: number = 1;
  pageToLoadNextBonusesClient: number = 1;

  private unsubscribe$ = new Subject();

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef, private bonusService: BonusService, private router: Router) {
    this.user$ = this.store.pipe(select(loginSelectors.selectUser));
  }

  ngOnInit() {
    this.store
      .select(loginSelectors.selectUser)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.user = user;
        this.cdr.detectChanges();
        this.loadBonusHistory();
        this.loadBonusHistoryClient();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  loadBonusHistory(page?: number) {
    if (this.loadingBonusHistory) {
      return;
    }

    this.loadingBonusHistory = true;
    let params: GetUserHistoryCriteria = {
      userIds: [this.user.id],
      sort: 'id',
      direction: 'DESC',
    };
    if (this.pageToLoadNextBonuses > 1) {
      params = { ...params, page: this.pageToLoadNextBonuses };
    }
    this.bonusService
      .getUserHistoryBonus(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.userBonusesHistory = [...this.userBonusesHistory, ...data.content];
        this.loadingBonusHistory = false;
        this.pageToLoadNextBonuses++;
      });
  }

  loadBonusHistoryClient(page?: number) {
    if (this.loadingBonusHistoryClient) {
      return;
    }

    this.loadingBonusHistory = true;
    let params: GetClientHistoryCriteria = {
      clientId: this.user.client.id,
      sort: 'id',
      direction: 'DESC',
    };
    if (this.pageToLoadNextBonusesClient > 1) {
      params = { ...params, page: this.pageToLoadNextBonusesClient };
    }
    this.bonusService
      .getClientHistoryBonus(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.clientBonusesHistory = [...this.clientBonusesHistory, ...data.content];
        this.loadingBonusHistoryClient = false;
        this.pageToLoadNextBonusesClient++;
      });
  }

  getOrderDetails(docNumber: string) {
    this.store.dispatch(new OrderSetActive({ docNumber }));
  }

  loadAllOrdersHistory() {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();

    this.store.dispatch(
      new OrderHistoryLoadRequest({
        pageNumber: 0,
        sort: [],
        pageSize: 9999,
        startDate,
        finishDate,
        status: [OrderStatus.NEW, OrderStatus.APPROVED, OrderStatus.DELIVERED, OrderStatus.DECLINED],
      })
    );
  }

  loadAllTasksHistory() {
    combineLatest([this.user$, this.activeOutlet$])
      .pipe(take(1))
      .subscribe(([user, outlet]) => {
        this.store.dispatch(
          new HistoryTaskLoadRequest({
            pageNumber: 0,
            pageSize: 9999,
            clientId: user.client.id,
            addressId: outlet.id,
            completed: true,
          })
        );
      });
  }

  loadAllPayHistory() {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();

    this.store.dispatch(new LoadPayHistory({ sort: '', size: 9999, page: 0, direction: '', startDate, endDate: finishDate }));
  }

  loadOrdersHistoryPages(page: number) {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();
    this.store.dispatch(new GetDelayedOrders({ page: page, size: 20 }));
    this.store.dispatch(new OrderHistoryLoadPageRequest({ page: page, itemsPerPage: 10, startDate: startDate, finishDate: finishDate }));
  }

  loadHistoryTasksPages(page: number) {
    this.store.dispatch(new HistoryTaskLoadPageRequest({ page: page, itemsPerPage: 10 }));
  }

  loadPayHistoryPages(itemsPerPage) {
    const [startDate, finishDate] = getGetStartDateAndFinishDate();
    this.store.dispatch(new LoadPayHistoryPage({ page: 0, itemsPerPage: itemsPerPage + 5, startDate: startDate, finishDate: finishDate }));
  }

  navToHistoryOrdersDetails({ data }: { data: string }) {
    this.router.navigate([`/history/orders`], { state: { data } });
  }
  navigateToHistory() {
    this.router.navigate(['history', 'orders']);
  }
}
