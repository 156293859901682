import { createSelector } from '@ngrx/store';

import { selectOutletState } from '../app.state';
import { OutletState } from './outlet.state';

export const outletSelectors = {
  selectOutlet: createSelector(selectOutletState, (state: OutletState) => state),
  selectError: createSelector(selectOutletState, (state: OutletState) => state.error),
  getActive: createSelector(selectOutletState, (state: OutletState) => state.active),
  selectOutletStatus: createSelector(selectOutletState, (state: OutletState) => state.statistic),
  getActiveId: createSelector(selectOutletState, (state) => state.active?.id),
  getOutlets: createSelector(selectOutletState, (state: OutletState) => state.data),
  getTotalPages: createSelector(selectOutletState, (state: OutletState) => state.totalOutletsPages),
  getTotalCount: createSelector(selectOutletState, (state: OutletState) => state.totalCount),
};
