import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { API_SCHOOL_URL } from '../../../../environments/environment';
import { News } from '@Mesh/core/models/news';

@Injectable({ providedIn: 'root' })
export class NewsService {
  private BASE_URL = `${API_SCHOOL_URL}/news`;

  constructor(private http: HttpClient) {}

  last(): Observable<News> {
    return this.http.get<any>(`${this.BASE_URL}/last`).pipe(
      map((data) => {
        return new News().deserialize(data['data']);
      })
    );
  }

  count(): Observable<number> {
    return this.http.get<any>(`${this.BASE_URL}/count`).pipe(
      map((data) => {
        return data.count;
      })
    );
  }
}
