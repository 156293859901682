import { LogoutRequest, RefreshToken } from '@Mesh/store/login/login.actions';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from '@Mesh/core/services';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { loginSelectors } from '@Mesh/store/login/login.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private token: string;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authenticationService: AuthenticationService, private store: Store<AppState>) {
    this.store.select(loginSelectors.selectToken).subscribe((token) => {
      this.token = token;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addContentTypeJson(request);
    request = this.removeNoContentTypeHeader(request);

    if (this.token && !request.url.includes('/token/refresh-token')) {
      request = this.addTokenHeader(request, this.token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          return this.handleUnauthorizedError(request, next);
        }
        return throwError(error);
      })
    );
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      this.store
        .select(loginSelectors.selectRefreshToken)
        .pipe(take(1))
        .subscribe((refreshToken) => {
          this.store.dispatch(new RefreshToken({ refreshToken }));
        });

      return this.store.select(loginSelectors.selectToken).pipe(
        filter((token) => !!token),
        take(1),
        switchMap((newToken) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(newToken);
          return next.handle(this.addTokenHeader(request, newToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.store.dispatch(new LogoutRequest());
          return throwError(err);
        })
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token)))
      );
    }
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private addContentTypeJson(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.headers.get('Content-Type') && !request.headers.has('no-content-type')) {
      return request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    return request;
  }

  private removeNoContentTypeHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (request.headers.has('no-content-type')) {
      return request.clone({ headers: request.headers.delete('no-content-type') });
    }

    if (request.url.endsWith('api/v1/content/doc')) {
      return request.clone({ headers: request.headers.delete('Content-Type') });
    }

    return request;
  }
}
