import { Deserializable } from './deserializable';
import { Character } from '@Mesh/core/models/character';
import { UserPoint } from '@Mesh/core/models/user-point';
import * as moment from 'moment';
import { AsyncItem } from './async-item';
import { Grade } from '@Mesh/core/models/grade';

export interface ModerationData {
  id?: number;
  alias?: string;
  description?: string;
  status?: string;
}

export interface CommunityData {
  alias: string;
  avatar: string;
  id: number;
  name: string;
  allow_write: boolean;
  rank: number;
  rating: number;
  users_count: number;
  moderation?: ModerationData;
}

export class UserSchool implements Deserializable {
  id?: number;
  name?: string;
  password?: string;
  surname?: string;
  avatar?: string;
  emodji?: string;
  patronymic?: string;
  position?: any;
  points?: number; //cash
  division_id?: number;
  position_id?: number;
  email?: string;
  phone?: string;
  bio?: string;
  sex?: string;
  birth_place?: string;
  cloth_size?: string;
  foot_size?: string;
  birthday_date?: string;
  employment_date?: string;
  login?: string;
  division?: string;
  head?: string;
  earned_for_month?: number;
  rank?: number;
  rank_shop?: number;
  rank_guild?: number;
  rank_clan?: number;
  rank_alliance?: number;
  rank_in_work_group?: number;
  rank_change?: number;
  score?: number; // skill level
  change: number;
  grade: number;
  total: number;
  must_change_password?: boolean;
  blocked?: boolean;
  character?: Character;
  current_task_level_name?: string;
  current_task_level_id?: number;
  can_upgrade?: boolean;
  kpi?: number;
  num_tasks?: number;
  count_tasks?: number;
  tour?: number;
  boss?: boolean;
  vkontakte: string;
  facebook: string;
  instagram: string;
  until_next_grade?: number;
  date_next_grade?: string;
  last_point?: UserPoint;
  tasks_done_count?: number;
  next_grade?: Grade;
  passed_next_grade?: boolean;
  characterMain: boolean;
  characterHide: boolean;
  active?: boolean;
  alliance?: CommunityData;
  clan?: CommunityData;
  guild?: CommunityData;
  shop?: CommunityData;

  get points_formatted() {
    return (this.points ? this.points : 0) + ' ' + this.declOfNum(this.points, ['Балл', 'Балла', 'Баллов']);
  }

  declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  }

  get full_name(): string {
    return (this.surname ? this.surname + ' ' : '') + this.name;
  }

  get sex_name() {
    switch (this.sex) {
      case 'M':
        return 'Мужской';
      case 'F':
        return 'Женский';
      default:
        return 'Не указано';
    }
  }

  get short_name(): string {
    if (this.surname && this.name) {
      return this.surname.charAt(0) + this.name.charAt(0);
    } else if (!this.surname && this.name) {
      return this.name.substr(0, 2).toUpperCase();
    }
  }

  get untilEndGrade() {
    let end = moment(this.date_next_grade),
      now = moment(new Date());

    if (end.isAfter(now)) {
      //@ts-ignore
      return moment.duration(end.diff(now)).format('D[д.] H[ч.] m[м.]');
    }

    return '';
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input) {
      if (input.character) {
        this.character = new Character().deserialize(input.character);
      }

      if (input.last_point) {
        this.last_point = new UserPoint().deserialize(input.last_point);
      }

      if (input.next_grade) {
        this.next_grade = new Grade().deserialize(input.next_grade);
      }

      if (typeof input.position === 'object' && input.position) {
        this.position = input.position.name;
      }
    }

    // if (this.id === 42) {
    //   this.current_task_level_name = 'ЖЮРИ';
    //   this.boss = true;
    //   this.sex = 'M';
    //   this.position = 'Генеральный директор';
    // }
    return this;
  }
}

export type AsyncUser = AsyncItem<UserSchool>;
