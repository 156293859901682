export enum Status {
  gold = 'Gold',
  silver = 'Silver',
  iron = 'Iron',
}

export interface Documents {
  id: number;
  clientId: number;
  isSigned: boolean;
  isDeclined: boolean;
  filename: string;
  documentType: string;
  url: string;
  iqReportId: number;
  startDate: string;
  endDate: string;
  signedAt: string;
  html: string;
  shouldSign: boolean;
  description?: string;
}

export interface Client {
  clientName: string;
  clientId: number;
  inn: string;
  kpp: null | number;
  ogrn: string;
  urAdr: string;
}

export interface User {
  addresses: string;
  avatar: string;
  client: Client;
  email: string;
  id: number;
  name: string;
  permissions: { addressId: number; permissions: string[] }[] | null;
  phone: string;
  position: string;
  possibleclientId: number;
  possibleEmail: string;
  possiblePhone: string;
  status: string;
  type: 'OWNER' | 'EMPLOYEE' | 'MANAGER';
}

export enum AgreementsType {
  SALEPLAN_DS = 'План продаж',
  TASK = 'Задание',
  TASK_SUPPLEMENT = 'Дополнение задачи',
  TASK_SUPPLEMENT_MATRIX = 'Дополнение задачи (матрица)',
  AGREEMENT_PARTICIPATION = 'Cоглашение об Участии',
}

export enum ActsType {
  SALEPLAN_ACT = 'Акт плана продаж',
  TASK_ACT = 'Акт задания',
  TASK_DETAIL = 'Детализация задания',
  TASK_MATRIX = 'Матрица задания',
  TASK_RECOMMENDED_ORDER = 'Рекомендовванный заказ',
  EQUIPMENT_INSTALL = 'Акт установки оборудования',
  EQUIPMENT_DEINSTALL = 'Акт демонтажа оборудования',
}

export enum RestType {
  LICENSE_AGREEMENT = 'Лицензионное соглашение',
  SUBLICENSE_AGREEMENT = 'Сублицензионное соглашение',
  RETAIL_DISTRIBUTION = 'Розниченое распространение',
  PERSONAL_DATA_AGREEMENT = 'Персональное соглашение',
  CLIENT_ADDRESSES = 'Адреса клиентов',
  USER_AGREEMENT = 'Пользовательское соглашение',
  MEGACOM_SUBLICENSE_AGREEMENT = 'Мегаком Сублицензионное соглашение',
}

export interface Outlet {
  name: string;
  statusPower: number;
  addressId: number;
  addressName: string;
  clientId: number;
  clientName: string;
  room: string;
  building: string;
  cityName: string;
  cityName1: string;
  district: string;
  guid: string;
  street: string;
  house: string;
  housing: string;
  plantSapId: string;
  postcode: number;
  regionCode: number;
  regionName: string;
  remark: string;
  salesOrgSapId: string;
  segment: Status;
  subdistributor: string;
  latitude: number;
  longitude: number;
  users: string;
}

export interface PostUpdateUser {
  avatar: string;
  name: string;
  position: string;
}

export enum POSTRequestStatus {
  NONE,
  SENT,
  SUCCESS,
  FAILURE,
}
