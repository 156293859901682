import { Injectable } from '@angular/core';
import { API_USER_URL } from '@Env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientBonus } from '@Mesh/core/models/client-bonus';
import { API_PLAN_URL, API_TASK_URL } from '@Env/environment';
import { ClientBonusPredict } from '@Mesh/core/models/client-bonus-predict';
import { GetOutletBonusCriteria } from '@Mesh/core/models/request/get-outlet-bonus-criteria';
import { Bonus } from '@Mesh/core/models/bonus';
import { stringifyObject } from '@Mesh/core/services/util';
import { OutletBonusPredict } from '@Mesh/core/models/outlet-bonus-predict';
import { GetUserHistoryCriteria } from '../models/request/get-user-bonus-history';
import { UserBonusBalance, UserHistoryBonus } from '../models/user-bonus-history';
import { IPageableList } from '../models/pageable';
import { ClientHistoryBonus } from '../models/client-bonus-history';
import { GetClientHistoryCriteria } from '../models/request/get-client-bonus-history';

@Injectable({
  providedIn: 'root',
})
export class BonusService {
  readonly baseUrlUserStage = `${API_USER_URL}/bonus`;
  readonly baseUrlSalePlanStage = `${API_PLAN_URL}/v1/bonus`;
  readonly baseUrlTaskStage = `${API_TASK_URL}/bonus`;

  constructor(private http: HttpClient) {}

  getClientBonus(clientId: number): Observable<ClientBonus> {
    return this.http.get<ClientBonus>(`${this.baseUrlUserStage}/${clientId}`);
  }

  getClientsBonusByPeriod(startAt: string, finishAt: string): Observable<ClientBonus[]> {
    return this.http.get<ClientBonus[]>(`${this.baseUrlUserStage}?startAt=${startAt}&finishAt${finishAt}`);
  }

  getClientBonusPredictFromTask(clientId: number): Observable<ClientBonusPredict> {
    return this.http.get<ClientBonusPredict>(`${this.baseUrlTaskStage}/${clientId}/predict`);
  }

  getClientBonusPredictFromSalePlan(clientId: number): Observable<ClientBonusPredict> {
    return this.http.get<ClientBonusPredict>(`${this.baseUrlSalePlanStage}/client/predict?clientId=${clientId}`);
  }

  getOutletBonus(param: GetOutletBonusCriteria): Observable<Bonus[]> {
    const stringData = stringifyObject(param);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<Bonus[]>(`${this.baseUrlSalePlanStage}`, { params });
  }

  getOutletBonusesPredict(param: GetOutletBonusCriteria): Observable<Bonus[]> {
    const stringData = stringifyObject(param);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<Bonus[]>(`${this.baseUrlSalePlanStage}/predict`, { params });
  }

  getOutletBonusPredict(addressId: number): Observable<OutletBonusPredict> {
    return this.http.get<OutletBonusPredict>(`${this.baseUrlTaskStage}/address/${addressId}/predict`);
  }

  getUserHistoryBonus(param: GetUserHistoryCriteria): Observable<IPageableList<UserHistoryBonus>> {
    const stringData = stringifyObject(param);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<IPageableList<UserHistoryBonus>>(`${this.baseUrlUserStage}/user/history`, { params });
  }

  getClientHistoryBonus(param: GetClientHistoryCriteria): Observable<IPageableList<ClientHistoryBonus>> {
    const stringData = stringifyObject(param);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<IPageableList<ClientHistoryBonus>>(`${this.baseUrlUserStage}/client/history`, { params });
  }

  getUserBonusBalance(userId: number): Observable<UserBonusBalance> {
    return this.http.get<UserBonusBalance>(`${this.baseUrlUserStage}/user/${userId}`);
  }
}
