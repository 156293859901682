import { Deserializable } from './deserializable';
import { Observable } from 'rxjs';
import { UserSchool } from './user-school';

export class Tag implements Deserializable {
  id?: number = null;
  user_field?: any;
  name?: string;
  slug?: string;
  users$: Observable<UserSchool[]>;
  query?: any;

  constructor(tag?: Partial<Tag>) {
    if (!tag) {
      return;
    }
    this.deserialize(tag);
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
