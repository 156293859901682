import { Deserializable } from './deserializable';
import { UserSchool } from './user-school';
import { AsyncItem } from './async-item';
import { TaskSchool } from './task-school';

export class UserTaskSchool implements Deserializable {
  static STATUS_START = 'S';
  static STATUS_TAKE = 'T';
  static STATUS_RETAKE = 'R';
  static STATUS_DONE = 'D';

  id?: number;
  start?: string;
  end?: string;
  begin?: string;
  deadline?: string;
  points?: number;
  progress?: number;
  passed?: boolean;
  expired?: boolean;
  charged?: boolean;
  status?: string;
  teacher?: UserSchool;
  director?: UserSchool;
  task?: TaskSchool;
  is_last_grade: boolean;
  allow?: boolean;
  is_grade: boolean;

  get isDone() {
    return this.status === UserTaskSchool.STATUS_DONE;
  }

  get status_name() {
    return {
      S: 'Назначена',
      T: 'В работе',
      R: 'Пересдача',
      D: 'Готово',
    }[this.status];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    this.points = this.points || 0;
    if (input.teacher) {
      this.teacher = new UserSchool().deserialize(input.teacher);
    }
    if (input.director) {
      this.director = new UserSchool().deserialize(input.director);
    }
    if (input.task) {
      this.task = new TaskSchool().deserialize(input.task);
    }
    return this;
  }
}

export type AsyncUserTaskSchool = AsyncItem<UserTaskSchool>;
