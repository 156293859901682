import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  AfterViewInit,
  NgZone,
} from '@angular/core';
import { Comment } from '@Mesh/core/models/comment';
import { User } from '@Mesh/core/models/user';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { ChatUserInfo } from '@Mesh/core/models/chat-user-info';
import { FORWARDED_MESSAGE } from '../chat-dialog.component';
import { Gallery, ImageItem } from '@ngx-gallery/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModuleTypes } from '@Mesh/shared/modules/chat/chat-dialog/chat-dialog.service';
import { TokenPipe } from '@Mesh/shared/modules/token/token.pipe';
import { IMAGES_URL, IMAGE_CLOUD_URL } from '@Env/environment';
import { Observable, of } from 'rxjs';
import { ChatTaskStepTypes } from '../../../../../core/models/chat-task-step-types';
import { AppState } from '../../../../../store/app.state';
import { Store } from '@ngrx/store';
import { orderSelectors } from '../../../../../store/order/order.selectors';
import { LoadBonusesRecommendedOrders } from '../../../../../store/order/order.actions';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent implements OnInit, AfterViewInit, AfterViewChecked {
  FORWARDED_MESSAGE = FORWARDED_MESSAGE;
  private _message: Comment;
  @Input() mine: boolean;
  @Input() isEditing: boolean;
  @Input() isLast: boolean;
  imagesUrl = IMAGE_CLOUD_URL;
  saleplanImgUrl = IMAGES_URL;
  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;

  images;
  videos = [];
  otherFiles;
  imagesQuote;
  videosQuote;
  imagesLoadedCount = 0;
  recognizedCigarettes$: Observable<any>;
  @Output() edit = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() forward = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() quote = new EventEmitter();
  @Output() quoteClick = new EventEmitter();
  @Output() imagesLoaded = new EventEmitter();
  @Output() loaded = new EventEmitter();
  @Output() approve = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() ticket = new EventEmitter();

  @ViewChild('basicMenu') public otherMenu: ContextMenuComponent;
  @ViewChild('triggerMenu') public triggerMenu: ElementRef<HTMLElement>;
  @ViewChild('element') public element: ElementRef<HTMLElement>;
  @ViewChild('video') public video: ElementRef<HTMLVideoElement>;
  galleryId;
  materialIds: Array<number> = [];
  stepTypes = ChatTaskStepTypes;
  bonusRecommendedOrders$ = this.store.select(orderSelectors.getBonusRecommendedOrders);

  get edited() {
    const { editedAt } = this._message;
    return editedAt;
  }

  approveTask() {
    this.approve.emit(this.message);
  }

  cancelTask() {
    this.cancel.emit(this.message);
  }

  closeTicket() {
    this.ticket.emit(this.message);
  }

  get message(): Comment {
    return this._message;
  }

  ngAfterViewInit(): void {
    this.loaded.emit(this.message);
    //console.log(this.images);
    this.imagesLoaded.emit(this.message.id);

    this.zone.runOutsideAngular(() => {
      if (this.video && this.video.nativeElement) {
        this.imageLoaded();
        this.video.nativeElement.src = '' + this.video.nativeElement.src;
      }
      if (this.message && this.images) {
        const lightboxGalleryRef = this.gallery.ref('lightbox' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const items = this.images.map((item) => new ImageItem({ src: item.original, thumb: item.url }));

        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(items);
      }
      if (this.message && this.imagesQuote) {
        const lightboxGalleryRef = this.gallery.ref('lightbox-qoute' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const items = this.imagesQuote.map((item) => new ImageItem({ src: item.original, thumb: item.url }));

        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(items);
      }
      if (this.message?.taskStep?.imageUrls) {
        const lightboxGalleryRef = this.gallery.ref('lightbox-task-step' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const items = this.message?.taskStep?.imageUrls.map(
          (item) => new ImageItem({ src: this.imagesUrl + item.url, thumb: this.imagesUrl + item.url })
        );

        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(items);
      }
    });
  }

  get imagesCount() {
    return (
      (this.images ? this.images.length : 0) + (this.imagesQuote ? this.imagesQuote.length : 0) + (this.videos ? this.videos.length : 0)
    );
  }

  imageLoaded() {
    this.imagesLoadedCount++;
    if (this.imagesLoadedCount === this.imagesCount) {
      this.imagesLoaded.emit(this.message.id);
    }
  }

  get empty() {
    return (
      this.message?.attached?.uploads?.length === 0 &&
      (this.message.text === '' || this.message.text === FORWARDED_MESSAGE || this.message.text === '[Пересланное сообщение]') &&
      this.message?.attached?.messages?.length === 0
    );
  }

  ngAfterViewChecked(): void {
    //console.log('parent - 4');
  }

  @Input()
  set message(value: Comment) {
    this._message = value;

    if (this.shouldProcessImages(this._message)) {
      this.processImages(this._message);
    }

    if (this.shouldLoadRecommendedOrders(this._message)) {
      this.loadRecommendedOrders(this._message);
    }

    this.updateMediaLists(this._message);
    this.cdr.detectChanges();
  }

  private shouldProcessImages(message: Comment): boolean {
    return !!(
      message.answer === 'image-media' &&
      (message.taskStep.type.name === 'PHOTO_MONITORING' ||
        (message.taskStep.type.name === 'SEND_PHOTO' && message.taskStep.additionalType === 'CALCULATE_REST_OF_PRODUCT')) &&
      message.taskStep.stepSuccess.recognitionResult &&
      message.taskStep.stepSuccess.recognitionResult.images
    );
  }

  private processImages(message: Comment): void {
    message.taskStep.stepSuccess.recognitionResult.images.forEach((image) => {
      image.imagePositions.forEach((coord) => this.materialIds.push(coord.materialId));
    });

    this.chatService.materialId = this.materialIds;
    this.chatService.addressId = message.addressId;
    this.getProducts(this.materialIds);
    this.fetchRecognitionResult(message.taskStep.stepSuccess.id);

    this.chatService.planId = [2222222214];
    this.chatService.stepId = message.taskStep.id;
  }

  private fetchRecognitionResult(stepSuccessId: number): void {
    this.chatService
      .recognitionResult(stepSuccessId)
      .pipe(
        catchError((error) => {
          console.error('Ошибка при получении результата распознавания:', error);
          return of(null);
        })
      )
      .subscribe((data) => {
        if (data && data.images && data.images.length > 0) {
          this._message.taskStep.stepSuccess.recognitionResult.id = data.images[0].id;
        } else {
          console.warn('В результате распознавания не найдено изображений');
        }
      });
  }

  private shouldLoadRecommendedOrders(message: Comment): boolean {
    return (
      message.taskStep &&
      message.taskStep.type.name === ChatTaskStepTypes.AUTO_RECOMMENDED_ORDER &&
      message.taskStep.stepSuccess.recommendedOrderId != null
    );
  }

  private loadRecommendedOrders(message: Comment): void {
    this.store.dispatch(
      new LoadBonusesRecommendedOrders({
        recommendedOrderId: message.taskStep.stepSuccess.recommendedOrderId,
        executed: false,
        predict: true,
      })
    );

    this.chatService.planId = [2222222214];
    this.chatService.stepId = message.taskStep.id;
  }

  private updateMediaLists(message: Comment): void {
    const imageExts = ['svg', 'jpeg', 'jpg', 'png', 'gif'];
    const videoExts = ['avi', 'mp4', 'webm', 'mov', 'ogg', '3gp', 'wmv', 'qt'];
    const audioExts = ['weba', 'wav', 'pgg'];

    this.images = message?.attached?.uploads?.filter((item) => imageExts.includes(item.ext));
    this.imagesQuote = message.attached?.messages[0]?.message?.attached?.uploads?.filter((item) => imageExts.includes(item.ext));
    this.videosQuote = message.attached?.messages[0]?.message?.attached?.uploads?.filter((item) => videoExts.includes(item.ext));
    this.videos = message?.attached?.uploads?.filter((item) => videoExts.includes(item.ext));
    this.otherFiles = message?.attached?.uploads?.filter((item) => ![...imageExts, ...videoExts, ...audioExts].includes(item.ext));
  }

  getProducts(materialIds: Array<number>): void {
    this.recognizedCigarettes$ = this.chatService.recognizedProducts(materialIds, this._message.addressId);
  }

  editMessage() {
    this.edit.emit(this._message);
  }

  removeMessage() {
    this.remove.emit(this._message);
  }

  forwardMessage() {
    this.forward.emit(this._message);
  }

  copyMessage() {
    if (this._message.text) {
      const inp = document.createElement('input');
      document.body.appendChild(inp);
      inp.value = this._message.text;
      inp.select();
      document.execCommand('copy', false);
      inp.remove();
    }
  }

  quoteMessage() {
    this.quote.emit(this._message);
  }

  showContextMenu($event: MouseEvent | any, right) {
    //console.log('press');
    const event = { ...$event };
    const el: HTMLElement = this.element.nativeElement;
    const clientRect = el.getBoundingClientRect();
    let posx = $event.pageX,
      posy = $event.pageY;
    if ($event.changedTouches) {
      posx = $event.changedTouches[0].pageX;
      posy = $event.changedTouches[0].pageY;
    }
    if ($event.targetTouches) {
      posx = $event.targetTouches[0].pageX;
      posy = $event.targetTouches[0].pageY;
    }
    //console.log('menu:', $event);
    event.clientX = posx;
    event.clientY = posy;
    this.contextMenuService.show.next({
      contextMenu: this.otherMenu,
      event: event,
      item: $event.target,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  log(message) {}

  constructor(
    public chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private contextMenuService: ContextMenuService,
    public domSanitizer: DomSanitizer,
    public tokenSafePipe: TokenPipe,
    private zone: NgZone,
    public gallery: Gallery,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.imagesLoadedCount = 0;
  }

  openMenu($event) {
    const el: HTMLElement = this.triggerMenu.nativeElement;
    //console.log($event.clientX);
  }

  get audios() {
    return this._message?.attached?.uploads?.reverse().filter((item) => ['weba', 'wav'].indexOf(item.ext) !== -1);
  }

  get files() {
    return this._message?.attached?.uploads
      ?.reverse()
      .filter((item) => ['svg', 'jpeg', 'jpg', 'png', 'gif', 'webm', 'weba'].indexOf(item.ext) === -1);
  }

  get hasContent() {
    return (
      (this.message.text &&
        this.message.text !== '' &&
        this.message.text !== FORWARDED_MESSAGE &&
        this.message.text !== '[Пересланное сообщение]') ||
      (this.files && this.files.length > 0) ||
      (this.audios && this.audios.length > 0)
    );
  }

  openChatModal(i) {}

  get oneMark() {
    const { readAt, createdAt } = this._message;
    return !createdAt || !readAt;
  }

  get twoMarks() {
    const { readAt, createdAt } = this._message;
    return createdAt && readAt;
  }

  getName(user: User | ChatUserInfo) {
    return `${user.surname ? user.surname : ''} ${user.surname ? user.name?.charAt(0).toUpperCase() : user.name}`;
  }

  get isMessageFromChat() {
    /*if (this.message.attached?.messages?.length > 0) {
      for (const message of this.message.attached.messages) {
        if (message?.message?.user.id !== this.message.replyUser?.id &&
          message?.message?.user.id !== this.message.user?.id
        ) {
          return false;
        }
      }
    }*/
    return true;
  }

  get stepIndex() {
    return this.message?.task?.step?.findIndex((step) => this.message?.taskStep?.id === step.id) + 1;
  }

  get isTaskMessage() {
    return this.message.type === 'task-comments';
  }

  get isModuleMessage() {
    return ModuleTypes.indexOf(this.message.type) !== -1;
  }

  toggleLike(isLikeOrDislike: boolean) {
    if (isLikeOrDislike) {
      if (this._message.liked) {
        this._message.liked = false;
        this._message.likes--;
      } else {
        this._message.liked = true;
        this._message.likes++;
      }
    } else {
      if (this._message.disliked) {
        this._message.disliked = false;
        this._message.dislikes--;
      } else {
        this._message.disliked = true;
        this._message.dislikes++;
      }
    }
    this.cdr.detectChanges();
    this.chatService.toggleLike(this._message.type, this._message.typeId, this._message.id, isLikeOrDislike);
  }

  acceptResult() {
    this.chatService.acceptResult().subscribe((data) => {});
  }
  cancelResult() {
    this.chatService.cancelResult().subscribe((data) => {});
  }
}
