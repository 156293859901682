import { Deserializable } from '@Mesh/core/models/deserializable';

export class Character implements Deserializable {
  id?: number;
  file?: string;
  sex?: string;
  poster?: string;
  avatar?: string;
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
