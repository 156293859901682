import { queryState } from '@Mesh/core/models/async-item';
import { News } from '@Mesh/core/models/news';
import { collapseState, fadeInAnimation, fadeOutAnimation } from '@Mesh/utils/animations/collapse-animations';
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { NewsService } from '../../../../core/services/school-services/news.service';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss', './ghost/ghost-item.component.scss'],
  animations: [collapseState, fadeOutAnimation, fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsWidgetComponent implements OnInit {
  state = queryState;
  news$: Observable<News>;
  isCollapsed = false;
  _active = true;
  isStub = false;

  config: SwiperConfigInterface = {
    init: true,
    direction: 'horizontal',
    initialSlide: 0,
    slidesPerView: 'auto',
    pagination: false,
    freeMode: true,
  };

  set Active(value: boolean) {
    this._active = value;
  }

  get Active(): boolean {
    return this._active;
  }

  constructor(private newsService: NewsService, private router: Router) {}

  ngOnInit() {
    this.getLastNews();
    this.toggleNavbar();
  }

  getLastNews() {
    this.news$ = this.newsService.last();
  }

  navigate() {
    this.router.navigate(['/news']);
  }

  goToUser(user) {
    return this.router.navigate([`/user/${user.id}`]);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.toggleNavbar();
  }

  toggleNavbar() {
    this.isCollapsed = window.innerWidth < 992;
  }

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.Active = !this.Active;
    } else {
      this.router.navigate(['/news']);
    }
  }
}
