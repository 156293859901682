import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { queryState } from '@Mesh/core/models/async-item';
import { AwardService } from '@Mesh/core/services/school-services/award.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Award } from '../../../../core/models/award';
import { UserSchool } from '../../../../core/models/user-school';
import { collapseState, fadeInAnimation, fadeOutAnimation } from '../../../../utils/animations/collapse-animations';

@Component({
  selector: 'app-auction-widget',
  templateUrl: './auction-widget.component.html',
  styleUrls: ['./auction-widget.component.scss', './ghost/ghost-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseState, fadeOutAnimation, fadeInAnimation],
})
export class AuctionWidgetComponent implements OnInit {
  @Input()
  set user(value: UserSchool) {
    const id = this.user?.id;
    this._user = value;

    if (this.user && this.user.id !== id) {
      this.init();
    }
  }

  state = queryState;
  award$: Observable<Award> = new BehaviorSubject<Award>(new Award());
  isCollapsed = false;
  _active = true;
  defaultImage = '/assets/stub/iqschool-truck.png';
  private _user: UserSchool;
  dummyAward: Award;

  get user(): UserSchool {
    return this._user;
  }

  set Active(value: boolean) {
    this._active = value;
  }

  get Active(): boolean {
    return this._active;
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.toggleNavbar();
  }

  toggleNavbar(): void {
    this.isCollapsed = window.innerWidth < 992;
    if (window.innerWidth <= 560) {
      this.Active = false;
      this.cdr.markForCheck();
    }
  }

  clickHeader(): void {
    if (this.isCollapsed) {
      this.Active = !this.Active;
    } else {
      this.router.navigate(['/shop']);
    }
  }

  constructor(private router: Router, private awardService: AwardService, private cdr: ChangeDetectorRef) {}

  auctionClick(id: number): void {
    if (id) {
      this.router.navigate(['/shop/product/', id]);
    }
  }

  init(): void {
    this.award$ = this.user ? this.awardService.last() : new BehaviorSubject<Award>(new Award());
  }

  ngOnInit(): void {
    this.createDummyAward();
    this.isCollapsed = window.innerWidth < 992;
    if (window.innerWidth <= 560) {
      this.Active = false;
      this.cdr.markForCheck();
    }
  }

  raiseBet(award_id: number): void {
    this.router.navigate(['/shop/product/', award_id]);
  }

  private createDummyAward(): void {
    this.dummyAward = new Award();
    this.dummyAward.main_image = '/assets/stub/iqschool-truck.png';
    this.dummyAward.name = 'Скоро';
    this.dummyAward.description = 'Здесь появятся товары!';
  }
}
