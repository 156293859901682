export interface PayHistory {
  id: number;
  burks: string;
  gjahr: number;
  belnr: string;
  clientId: number;
  dmbtr: number;
  kvgr1: string;
  buDate: string;
  kkber: string;
  kvgr1_t: string;
  butxt: string;
  externalContractNumber: string;
  bonus: boolean;
  limit: number;
}

export interface Balance {
  id: number;
  burks?: string;
  buText?: string;
  buDate: string;
  salesOrgSapId?: string;
  salesOrgSapName?: string;
  clientId?: number;
  clientName?: string;
  kkber?: string;
  kkbText?: string;
  externalContractNumber?: string;
  debLimit: number;
  debts: number;
  plan: number;
  debtswa: number;
  delayed: number;
  balance: number;
}

export enum Status {
  gold = 'Gold',
  silver = 'Silver',
  iron = 'Iron',
}

export interface Outlet {
  name: string;
  statusPower: number;
  addressId: number;
  addressName: string;
  clientId: number;
  clientName: string;
  room: string;
  building: string;
  cityName: string;
  cityName1: string;
  district: string;
  guid: string;
  street: string;
  house: string;
  housing: string;
  plantSapId: string;
  postcode: number;
  regionCode: number;
  regionName: string;
  remark: string;
  salesOrgSapId: string;
  segment: Status;
  subdistributor: string;
  latitude: number;
  longitude: number;
  users: string;
}

export enum OrderStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  DELIVERED = 'DELIVERED',
  DECLINED = 'DECLINED',
}

export enum UnitIdName {
  BLK = 'BLK',
  KOP = 'KOP',
  ST = 'ST',
  PAK = 'PAK',
}

export interface HistoryData {
  cost: number;
  countProduct: number;
  crTime: string;
  docDate: string;
  docDateTime: string;
  docNumber: string;
  orderNumber: string;
  status: OrderStatus;
  delivered: null;
  sapStatus: null;
  isOpenedDetails?: boolean;
}
export interface Task {
  active: boolean;
  addressId: number;
  clientId: number;
  comment: string;
  cost: number;
  countStep: number;
  dateEnd: string;
  dateOfCreation: string;
  dateStart: string;
  id: number;
  imageUrl: string;
  name: string;
  notOverdue: boolean;
  onModeration: boolean;
  taskCompleted: boolean;
  status: string;
  step: Step[];
  progress?: number;
}
export interface Step {
  additionalType: null;
  comment: string;
  cost: number;
  description: string;
  id: number;
  imageUrls: any[];
  name: string;
  planId: number;
  question: null;
  reply: any[];
  requiredCountMedia: null;
  step: number;
  taskId: number;
  type: StepType;
  videoUrls: any[];
}
export interface StepType {
  description: string;
  goldCost: number;
  ironCost: number;
  name: string;
  silverCost: number;
  title: string;
  uncategoryCost: number;
}

export interface OrderDetails {
  docNumber: string;
  delivQt: number;
  quant: number;
  amount: number;
  netAmount: number;
  delivNetAmount: number;
  delivAmount: number;
  unitId: string;
  product: {
    id: number;
    materialId: number;
    materialName: string;
    parentMaterialId: number;
    parentMaterialName: string;
    sectorName: string;
    sectorId: string;
    categoryId: number;
    materialGroupName: string;
    manufacturerName: string;
    categoryName: string;
    brandId: number;
    brandName: string;
    productTypeName: string;
    brandFamilyName: string;
    materialGroupNameId: string;
    manufacturerNameId: string;
    categoryNameId: string;
    brandNameId: string;
    brandFamilyNameId: string;
    productTypeNameId: string;
    url: string;
    active: true;
  };
  crTime: {
    hour: number;
    minute: number;
    second: number;
    nano: number;
  };
  sapStatus: string;
  status: number;
  docDateTime: string;
  delivered: boolean;
}

export interface FinanceHistory {
  sum: number;
  date: string;
  number: number;
  paymentMethod?: string;
}

export interface UserHistoryBonus {
  id: number;
  userId: number;
  bonus: number;
  currentBonus: number;
  title: string;
  body: string;
  date: string;
}

export interface ClientHistoryBonus {
  id: number;
  bonus: number;
  currentBonus: number;
  clientId: number;
  title: string;
  body: string;
  date: string;
}
