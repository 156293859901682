import { Injectable } from '@angular/core';
import { GetOrderHistoryCriteria } from '@Mesh/core/models/request/get-order-history-criteria';
import { Observable } from 'rxjs';
import { OrderDetails, OrderData } from '@Mesh/core/models/order';
import { HttpClient, HttpParams } from '@angular/common/http';
import { stringifyObject } from '@Mesh/core/services/util';
import { API_PLAN_URL } from '@Env/environment';
import { map } from 'rxjs/operators';
import { objectToParams } from '../../shared/helpers/object.helpers';
import { Bonus } from '../models/bonus';
import {
  BonusesRecommendedOrdersCriteria,
  OrderHistoryItem,
  OrderHistoryItemsCriteria,
  RecommendOrderBonus,
} from '../../store/order/order.model';
import { DelayedOrder, DelayedOrderCriteria, DelayedOrderUpdatePayload } from '../models/delayed-order';
import { PageableList } from '../models/pageable';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private readonly baseUrl = API_PLAN_URL;

  constructor(private http: HttpClient) {}

  getOrdersByOutlet(data: Partial<GetOrderHistoryCriteria>): Observable<OrderData> {
    const stringData = stringifyObject(data);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<OrderData>(`${this.baseUrl}/v1/order-history`, { params });
  }

  getOrderDetails(documentNumber: string): Observable<OrderDetails[]> {
    return this.http
      .get<OrderDetails[]>(`${this.baseUrl}/v1/order-history/details/${documentNumber}`)
      .pipe(map((elements) => elements.map((details) => ({ ...details, docNumber: documentNumber }))));
  }

  updateOrder(docNumber: string, payload: Partial<OrderDetails>): Observable<OrderDetails[]> {
    return this.http.patch<OrderDetails[]>(`${this.baseUrl}/v1/order-history/${docNumber}`, payload);
  }

  getBonusRecommendedOrders(addressId: number, criteria: BonusesRecommendedOrdersCriteria): Observable<RecommendOrderBonus[]> {
    const params = objectToParams(criteria);
    return this.http.get<RecommendOrderBonus[]>(`${this.baseUrl}/v1/orders/recommended/bonus/${addressId}`, { params });
  }

  getHistoryRecommendedOrders(addressId: number, criteria: any): Observable<any> {
    const params = objectToParams(criteria);
    return this.http.get<any>(`${this.baseUrl}/v1/orders/recommended/bonus/${addressId}/filterBonus`, { params });
  }

  getBonusRecommendedOrdersCategory(addressId: number, criteria: any): Observable<any> {
    const params = objectToParams(criteria);
    return this.http.get<any>(`${this.baseUrl}/v1/orders/recommended/bonus/${addressId}/filterBonusByHierarchy`, { params });
  }

  getDelayedOrders(addressId: number, criteria?: DelayedOrderCriteria): Observable<PageableList<DelayedOrder>> {
    const params = objectToParams(criteria);
    return this.http.get<PageableList<DelayedOrder>>(`${this.baseUrl}/v1/cart/${addressId}/delayed`, { params });
  }

  getDelayedOrder(addressId: number, delayedOrderId: number): Observable<DelayedOrder> {
    return this.http.get<DelayedOrder>(`${this.baseUrl}/v1/cart/${addressId}/delayed/${delayedOrderId}`);
  }

  dropDelayedOrderForAddress(addressId: number, delayedOrderId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/v1/cart/${addressId}/drop/delayed/${delayedOrderId}`);
  }

  updateDelayedCart(addressId: number, payload: DelayedOrderUpdatePayload): Observable<DelayedOrder> {
    return this.http.put<DelayedOrder>(`${this.baseUrl}/v1/cart/${addressId}/delayed`, payload);
  }

  requestInvoiceDelayedCart(delayedOrderId: number, criteria: { shouldRequestInvoice: boolean }): Observable<DelayedOrder> {
    const params = objectToParams(criteria);
    return this.http.put<DelayedOrder>(`${this.baseUrl}/v1/cart/delayed/${delayedOrderId}/invoices`, {}, { params });
  }

  getOrdersByItem(criteria: Partial<OrderHistoryItemsCriteria>): Observable<PageableList<OrderHistoryItem>> {
    const params = objectToParams(criteria);
    return this.http.get<PageableList<OrderHistoryItem>>(`${this.baseUrl}/v1/order-history/items`, { params });
  }
}
