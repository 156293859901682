import { User } from './user';
import { Tag } from './tag';
import { Deserializable } from './deserializable';
import { Material } from './material';
import { CourseModule } from './course-module';
import * as moment from 'moment';
import 'moment-duration-format';
import { UserCourse } from './user-course';
import { UserModule } from './user-module';

export class Course implements Deserializable {
  get training_time() {
    return moment['duration'](this._training_time, 'minutes')['format']('h:mm');
  }

  set training_time(time) {
    this._training_time = time;
  }

  id?: number;
  image?: {
    list?: string;
    view?: string;
    main?: string;
  };
  description?: string;
  complexity?: number;
  purpose?: string;
  result?: string;
  _training_time?: number;
  calendar_time?: number;
  hit?: boolean;
  new?: boolean;
  recommend?: boolean;
  charged?: boolean;
  name?: string;
  code?: string;
  full_program?: string;
  teacher?: User;
  tags?: Tag[];
  points?: number;
  max_points?: number;
  num_users?: number;
  participants_count?: number;
  appointed?: boolean;
  start?: string;
  end?: string;
  featured?: boolean;
  materials?: Material[];
  modules?: any[];
  user_course?: any;
  likes_count?: number;
  dislikes_count?: number;
  liked?: boolean;
  disliked?: boolean;
  created_at?: number;
  updated_at?: number;
  tags_slugs: string[];
  user_module?: any;
  user_course_module?: any;
  type?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.type = 'course';
    if (input.teacher) {
      this.teacher = new User().deserialize(input.teacher);
    }
    if (input.user_course) {
      this.user_course = new UserCourse().deserialize(input.user_course);
    }
    if (input.tags) {
      const tags: Tag[] = [];
      input.tags.map((data) => {
        tags.push(new Tag().deserialize(data));
      });
      this.tags = tags;

      this.tags_slugs = this.tags.map((tag) => {
        return tag.slug;
      });
    }

    if (input.modules) {
      const modules: CourseModule[] = [];
      input.modules.map((data) => {
        modules.push(new CourseModule().deserialize(data));
      });
      this.modules = modules;
    }

    if (input.materials) {
      const materials: Material[] = [];
      input.materials.map((data) => {
        materials.push(new Material().deserialize(data));
      });
      this.materials = materials;
    }
    return this;
  }
}
