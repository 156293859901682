import { trigger, state, style, transition, animate } from '@angular/animations';
import { fadeOut, fadeIn } from './fade-animations';

export const collapseState = trigger('collapseState', [
  state(
    'inactive',
    style({
      opacity: '0',
      height: 0,
      display: 'none',
    })
  ),
  state(
    'active',
    style({
      opacity: '1',
      height: '*',
      display: '*',
    })
  ),
  transition('inactive => active', animate('125ms ease-in')),
  transition('active => inactive', animate('125ms ease-out')),
]);

export const fadeOutAnimation = trigger('fadeOut', fadeOut());

export const fadeInAnimation = trigger('fadeIn', fadeIn());
