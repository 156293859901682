import { Deserializable } from './deserializable';
import { UserCourseModule } from './user-course-module';
import { UserCourseModuleAllow } from './user-course-module-allow';

export class CourseModule implements Deserializable {
  id?: number;
  module_name?: string;
  module_id?: number;
  module_type?: string;
  user_course_module?: UserCourseModule;
  user_course_module_allow?: UserCourseModuleAllow;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.user_course_module) {
      this.user_course_module = new UserCourseModule().deserialize(input.user_course_module);
    }
    if (input.user_course_module_allow) {
      this.user_course_module_allow = new UserCourseModuleAllow().deserialize(input.user_course_module_allow);
    }
    return this;
  }
}
