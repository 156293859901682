import { Injectable } from '@angular/core';
import { PartialObserver, Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotService {
  private subjects: Map<string, Subject<any>> = new Map();

  public get(id: string) {
    if (!this.subjects.has(id)) {
      this.subjects.set(id, new Subject());
    }
    return this.subjects.get(id);
  }

  public subscribe(id: string, observer: PartialObserver<any>): Subscription {
    return this.get(id).subscribe(observer);
  }

  public emit(id: string, value: any) {
    if (this.subjects.has(id)) {
      this.subjects.get(id).next(value);
    }
  }

  public unsubscribe(id: string) {
    if (this.subjects.has(id)) {
      return this.subjects.get(id).unsubscribe();
    }
  }
}

export enum Notifications {
  MenuOpened = 'menu_opened',
  ChangePassword = 'change_password',
  ChangePhone = 'change_phone',
  TaskCountUpdated = 'task_count_updated',
  Avatar3DSelect = 'avatar_3d_select',
  TaskAvailable = 'task_available',
  TaskCertificate = 'task_certificate',
  GroupCertificate = 'group_certificate',
}
