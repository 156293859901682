import { AsyncItem } from './async-item';
import { Deserializable } from './deserializable';
import { Goblet } from './goblet';
import { PassedEntity } from './passed-entity';

export class TaskLevel implements Deserializable {
  id: number;
  name: string;
  goblet: string;
  goblets: Goblet[];
  passed_entity: PassedEntity;
  in_progress: boolean;
  deserialize(input: any): this {
    Object.assign(this, input);
    if (input) {
      if (input.passed_entity) {
        this.passed_entity = new PassedEntity().deserialize(input.passed_entity);
      }
    }
    return this;
  }
}

export type AsyncTaskLevel = AsyncItem<TaskLevel>;
