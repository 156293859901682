import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { DataService } from '@Mesh/core/services';
import { forkJoin, Observable, of } from 'rxjs';
import { PlanActionsTypes, PlanLoadError, PlanLoadRequest, PlanLoadSuccess, PlanSetActive } from '@Mesh/store/plan/plan.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { PlanService } from '@Mesh/core/services/plan.service';
import { IProductParamPlan, ProductTableService } from '@Mesh/core/services/product-table.service';

@Injectable()
export class PlanEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private dataService: DataService,
    private planService: PlanService,
    private productTableService: ProductTableService
  ) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<PlanLoadRequest>(PlanActionsTypes.PlanLoadRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([, activeOutlet]) => {
      const planTop$ = this.dataService.getPlanTop(activeOutlet);
      const planSale$ = this.dataService.getPlanSale(activeOutlet);

      return forkJoin([planTop$, planSale$]).pipe(map(([p1, p2]) => [...p1, ...p2]));
    }),
    map((data) => {
      return new PlanLoadSuccess({ plan: this.planService.salePlansMapping(data) });
    }),
    catchError((error) => of(new PlanLoadError(error)))
  );

  @Effect({ dispatch: false })
  setActive: Observable<Action> = this.actions$.pipe(
    ofType<PlanSetActive>(PlanActionsTypes.PlanSetActive),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    mergeMap(([action, activeOutlet]) => {
      const query: IProductParamPlan = {
        addressId: activeOutlet.id,
        clientId: activeOutlet.client.id,
        pageNumber: 0,
        pageSize: 8,
        planId: action.payload.active.plan_id,
        sort: 'name|ASC',
      };
      this.productTableService.setQuery(query);
      return of(null);
    })
  );
}
