import { Deserializable } from './deserializable';

export class Material implements Deserializable {
  id?: number;
  name?: string;
  file?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
