import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { User } from '@Mesh/core/models/user';
import { Comment } from '@Mesh/core/models/comment';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';

@Component({
  selector: 'app-chat-available-dialog-item',
  templateUrl: './chat-available-dialog-item.component.html',
  styleUrls: ['./chat-available-dialog-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatAvailableDialogItemComponent implements OnInit {
  @Output() dialogSelect = new EventEmitter<{ dialog: Comment }>();
  @Output() onSettings = new EventEmitter();
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;

  @Input() dialog: Comment;
  @Input() currentUser: User;

  get oneMark() {
    const { readAt, createdAt } = this.message;
    return !createdAt || !readAt;
  }

  get twoMarks() {
    const { readAt, createdAt } = this.message;
    return createdAt && readAt;
  }

  get message() {
    return this.dialog.message;
  }

  get user() {
    const { replyUser, user } = this.message;
    return replyUser?.id !== this.currentUser.id ? replyUser || user : user || replyUser;
  }

  get mine() {
    const { user } = this.message;
    return !this.message.senderAddressId;
  }

  get isTaskDialog() {
    return this.dialog.type === 'task-comments';
  }

  get isSupportDialog() {
    return this.dialog.type === 'support';
  }

  attachDialog() {
    this.onSettings.emit(false);
    this.chatService
      .togglePin(this.dialog, this.dialog.type, this.dialog.typeId, !this.dialog.settings.pinned)
      .then((settings) => this.onSettings.emit(settings));
  }

  get onlyImages() {
    return (
      this.dialog.message.text === '' &&
      this.dialog.message.attached.uploads.filter((item) => ['svg', 'jpeg', 'jpg', 'png', 'gif'].indexOf(item.ext) !== -1).length > 0
    );
  }

  noSound() {
    this.onSettings.emit(false);
    this.chatService
      .toggleSound(this.dialog, this.dialog.type, this.dialog.typeId, !this.dialog.settings.muted)
      .then((settings) => this.onSettings.emit(settings));
  }

  remove() {
    this.onSettings.emit(false);
    this.chatService.removeDialog(this.dialog, this.dialog.type, this.dialog.typeId).then((settings) => this.onSettings.emit(settings));
  }

  constructor(private chatService: ChatService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {}
}
