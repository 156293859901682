import { Address } from './address';

export interface Outlet extends Address {}

export enum Status {
  gold = 'Gold',
  silver = 'Silver',
  iron = 'Iron',
}

export interface OutletStatistic {
  success: number;
  moderation: number;
  total: number;
  cost: number;
}
