import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, HostBinding, Output } from '@angular/core';
import { Comment, TaskStatus } from '@Mesh/core/models/comment';
import { ChatTaskStepTypes } from '../../../../../core/models/chat-task-step-types';
import { Observable } from 'rxjs';
import { ChatService } from '../../chat.service';
import { LeftoverProduct, UpdateLeftoverPayload } from '../../../../../core/models/update-leftover-products';
import {} from 'events';

@Component({
  selector: 'app-chat-task-actions',
  templateUrl: './chat-task-actions.component.html',
  styleUrls: ['./chat-task-actions.component.scss'],
})
export class ChatTaskActionsComponent implements OnInit {
  @Input()
  set message(value: Comment) {
    this._message = value;
    if (value) {
      this.setTypeTaskAction(value);
      this.getStateExpanded();
    }
  }
  get message(): Comment {
    return this._message;
  }
  @Input() disabled: boolean;
  @Input() clientId: number;
  @Input() set files(value: File[]) {
    if (value && value.length) {
      this.filesAttached = true;
    } else {
      this.filesAttached = false;
    }
  }
  filesAttached: boolean;
  @Output() sendedMessageTask: EventEmitter<any> = new EventEmitter();
  @Output() changedFiles: EventEmitter<any> = new EventEmitter();
  @Output() sendedFilesTask: EventEmitter<any> = new EventEmitter();
  @Output() createdAutoOrder: EventEmitter<any> = new EventEmitter();

  collapsedValue: boolean = true;
  @HostBinding('class.collapsed-task')
  get collapsed(): boolean {
    return this.collapsedValue;
  }

  @HostBinding('class.expanded-task')
  get expanded(): boolean {
    return !this.collapsed;
  }
  private _message: Comment;
  titleButton: string = 'Сделать фото';
  ChatTaskStepTypes = ChatTaskStepTypes;
  productsStock$: Observable<any>;
  openedRestProduct: boolean = false;
  TaskStatus = TaskStatus;
  timer: any;
  isLoading: boolean;
  constructor(private chatService: ChatService) {}

  ngOnInit() {}

  onToggleRestProduct() {
    this.onOpen();
    this.openedRestProduct = true;
  }

  onOpen() {
    if (!this.disabled) {
      this.collapsedValue = false;
    }
  }

  onClose() {
    if (!this.disabled) {
      this.collapsedValue = true;
    }
  }

  onToggle() {
    if (!this.disabled) {
      this.collapsedValue = !this.collapsed;
    }
  }

  setTypeTaskAction(message: Comment) {
    if (message.taskStep && message.taskStep.type.name === ChatTaskStepTypes.CALCULATE_REST_OF_PRODUCT) {
      this.getStockProducts();
    }

    if (message.taskStep && message.taskStep.type.name === ChatTaskStepTypes.AUTO_RECOMMENDED_ORDER) {
      setTimeout(() => {
        this.isLoading = true;
      }, 500);
    }
  }

  getStockProducts() {
    let params = {
      clientId: this.clientId,
      pageSize: 10,
      addressId: this.message.addressId,
      ignoreStock: true,
    };
    if (this.message.taskStep.recommendedOrderConfigId) {
      params = { ...params, ...{ recommendedOrderConfigId: this.message.taskStep.recommendedOrderConfigId } };
    }
    this.productsStock$ = this.chatService.getStockProducts(params);
  }

  onLeftoverProducts(data: { [x: string]: number }) {
    this.setLeftoverProducts(data);
  }

  onModerationRestProduct() {
    this.onClose();
  }

  setLeftoverProducts(data: { [x: string]: number }) {
    const payload: UpdateLeftoverPayload = {
      addressId: this.message.addressId,
      items: Object.keys(data).map((key) => ({ materialId: key, quantity: data[key] } as unknown as LeftoverProduct)),
    };
    this.chatService.setLeftoverProducts(payload).subscribe((res) => {
      this.sendedMessageTask.emit({ message: this.message.taskStep.name, answer: res.id });
      this.openedRestProduct = false;
      this.onClose();
    });
  }

  get statusNew() {
    return (
      (this.message && this.message?.progressOutlet?.taskStatus === TaskStatus.NEW) ||
      this.message?.progressOutlet?.taskStatus === TaskStatus.NOT_ACCEPTED
    );
  }

  get statusAccepted() {
    return this.message && this.message?.progressOutlet?.taskStatus === TaskStatus.ACCEPTED;
  }

  get statusModeration() {
    return this.message && this.message?.progressOutlet?.taskStatus === TaskStatus.CHECKING;
  }

  get statusProgress() {
    return this.message && this.message?.progressOutlet?.taskStatus === TaskStatus.PROGRESS;
  }

  getStateExpanded() {
    if (this.statusAccepted) {
      this.onOpen();
    }
  }

  onChangeFiles() {
    this.changedFiles.emit();
  }

  onSendFiles() {
    this.sendedMessageTask.emit({ message: this.message.taskStep.name, answer: 'image-media' });
  }

  onCreateAutoOrder() {
    this.createdAutoOrder.emit({ recommendedOrderId: this.message.taskStep.stepSuccess.recommendedOrderId });
  }
}
