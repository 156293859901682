import { IPageableList } from './../models/pageable';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Outlet } from '@Mesh/core/models';
import { Observable } from 'rxjs';
import { isBoolean } from 'ngx-bootstrap/chronos/utils/type-checks';
import { pluck } from 'rxjs/operators';
import { API_USER_URL } from '@Env/environment';
import { GetOutletCriteria } from '@Mesh/core/models/request/get-outlet-criteria';

@Injectable({
  providedIn: 'root',
})
export class OutletService {
  private readonly baseUrl = API_USER_URL;

  constructor(private readonly http: HttpClient) {}

  getOutlets(param: Partial<GetOutletCriteria>): Observable<Outlet[]> {
    let params = new HttpParams();
    if (param.clientId) {
      params = params.append('clientId', `${param.clientId}`);
    }
    if (param.addressNamePattern) {
      params = params.append('addressNamePattern', `${param.addressNamePattern}`);
    }
    if (param.segment) {
      params = params.append('segment', `${param.segment}`);
    }
    if (param.ids) {
      params = params.append('ids', `${param.ids}`);
    }
    params = params.append('extendedFormat', `${isBoolean(param.extendedFormat) ? param.extendedFormat : true}`);
    if (param.onlyActive) {
      params = params.append('onlyActive', `${param.onlyActive}`);
    }
    if (param.sortByStreet) {
      params = params.append('sortByStreet', `${param.sortByStreet}`);
    }
    params = params.append('sortByEnabledPlans', 'true');
    return this.http.get<Outlet[]>(`${this.baseUrl}/address/filter`, { params });
  }
}
