import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MESSAGING_URL } from '@Env/environment';
import { AppState } from '@Mesh/store/app.state';
import { Store } from '@ngrx/store';
import { NotificationTokenRequest, NotificationToken, NotificationRequest, NotificationsData, Notification } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly connectionString = MESSAGING_URL;

  constructor(private readonly http: HttpClient, private router: Router, private store: Store<AppState>, private route: ActivatedRoute) {}

  updateToken(request: NotificationTokenRequest) {
    return this.http.post(`${MESSAGING_URL}/token`, request).map((token: any) => {
      return token as NotificationToken;
    });
  }

  readNotification(id: number) {
    return this.http.put(`${MESSAGING_URL}/notifications/read/${id}`, {}).map((notification: any) => {
      return notification as Notification;
    });
  }

  // goToNotification(notification) {
  //   switch (notification.entityType) {
  //     case 'support':
  //       this.store.dispatch(
  //         openSupportChat({
  //           userId: notification.payload.sentBy,
  //           addressId: notification.payload.addressId,
  //           clientId: notification.payload.clientId,
  //         })
  //       );
  //       break;
  //   }
  //   return of(true);
  // }

  getNotifications(request: NotificationRequest) {
    let params = new HttpParams();
    const keys = Object.keys(request);
    keys.forEach((k) => {
      params = params.append(k, `${request[k]}`);
    });
    return this.http.get(`${MESSAGING_URL}/notifications/by-params`, { params }).map((notifications: any) => {
      return notifications as NotificationsData;
    });
  }
}
