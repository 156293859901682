import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ViewRef,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  AfterViewInit,
  AfterViewChecked,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, flatMap, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ChatDialogService, ChatDialogType, ResponseType, ITypingUser } from './chat-dialog.service';
import { User } from '@Mesh/core/models/user';
import { takeUntil, first } from 'rxjs/operators';
import { Comment } from '@Mesh/core/models/comment';
import { ChatMessageQuote } from '../models/chat-message-quote';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import * as RecordRTC from 'recordrtc';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { MessagesService } from '@Mesh/core/services/chat/messages.service';
import { SoundService } from '@Mesh/core/services/chat/sound.service';
import { UserService } from '@Mesh/core/services/user.service';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { pagesToggleService } from '@Mesh/@pages/services/toggler.service';

declare var StereoAudioRecorder: any;
export const FORWARDED_MESSAGE = `«Пересланное сообщение»`;

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDialogComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  u$ = new Subject();

  _record: User | { type: ChatDialogType; info: any; messages: any[] };
  _dialog: { type: string; typeId: any; task?: any; module?: any; approve?: any; cancel?: any };
  @ViewChild(NgxDropzoneComponent) dropzone: NgxDropzoneComponent;
  @ViewChildren('chatList', { read: ElementRef }) chatListRefs: QueryList<ElementRef>;

  _scrollNew = 0;
  firstNotLoaded = false;
  stream;
  record$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dialog$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: -1,
  };
  realDialog: any;

  cancel = false;
  approve = false;
  error = false;
  ticket = false;

  get scrollNew() {
    return this._scrollNew;
  }

  set scrollNew(value) {
    if (this.isUserNearBottom() || value === 0) {
      this._scrollNew = value;
    }
  }

  @Input()
  set record(value) {
    this._record = value;
    this.record$.next(value);
    this.chatDialogService.update$.next(value);
  }

  get record() {
    return this._record;
  }

  @Input()
  set dialog(value) {
    this._dialog = value;
    this.realDialog = this._dialog;
    this.dialog$.next(value);
  }

  get dialog() {
    return this._dialog;
  }

  @Output('onBack') onBack = new EventEmitter();
  @Output('onForward') onForward = new EventEmitter();
  _searchQuery: string = null;
  communitiesSwiperIndex = 0;
  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    // spaceBetween: 16,
    slidesPerView: 'auto',
    freeMode: true,
    preloadImages: true,
    pagination: false,
    centeredSlides: false,
  };
  guild$;
  alliance$;
  clan$;
  all$;
  scrolledToBottom = false;
  loading;
  recording;

  @ViewChildren(SwiperDirective) swiperViewes: QueryList<SwiperDirective>;
  @ViewChild(SwiperDirective) swiper: SwiperDirective;

  spinner = SPINNER;
  LOADER_ID = 'chat-dialog-loader';
  current_user: User;
  messages$;
  dialogs$;
  dialogs;
  taskDialogs;
  chatDialogs;
  messages;
  lastIndex;
  page = 1;
  loadOnScroll = false;
  loadImage = false;
  loadMessagesCount = {};
  @ViewChildren('messages') messagesQuery: QueryList<any>;
  @ViewChild(PerfectScrollbarDirective) messagesWrapper: PerfectScrollbarDirective;
  @ViewChild('messagesWrapper') infiniteScrollEl: ElementRef;
  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;
  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('chatHistoryWrapper') chatHistoryWrapper: ElementRef;
  private _stickyHeader: boolean = false;
  _stepId;
  recorder;

  userMessage = '';
  editingMessage: Comment = null;
  quotingMessage: Comment = null;
  forwardingMessage: Comment = null;
  dzIsHidden = true;
  first = true;
  hide = true;
  typingUsers$;
  allTypingUsers: ITypingUser[] = [];
  currentTypingUsers: ITypingUser[] = [];

  typingReplyUser: ITypingUser;
  timeoutTyping: ReturnType<typeof setTimeout>;
  typing: boolean = false;

  get stickyHeader(): boolean {
    return this._stickyHeader;
  }

  get searchQuery() {
    return this._searchQuery;
  }

  set searchQuery(value) {
    this._searchQuery = value;
  }

  get stepId() {
    return this._stepId;
  }

  set stepId(value) {
    this._stepId = value;
    this.chatDialogService.dialog.stepId = value;
  }

  set stickyHeader(value: boolean) {
    this._stickyHeader = value;
    //this.cdr.detectChanges();
  }

  get chatStatus() {
    switch (this.chatType) {
      case 'user':
        return (this.chatRecord as any)?.status?.online ? 'Онлайн' : 'Офлайн';
      case 'guild':
        return 'Твоя гильдия';
      case 'alliance':
        return 'Твой клан';
      case 'clan':
        return 'Твой альянс';
      default:
        return '';
    }
  }

  typingSubject = new Subject<string>();

  onKeyUp(event: KeyboardEvent) {
    if (!this.typing) {
      this.typing = true;
      this.emitTypingEvent();
      const timeout = setTimeout(() => {
        this.typing = false;
        clearTimeout(timeout);
      }, 500);
    }
  }

  private emitTypingEvent() {
    if (this.dialog && this.current_user) {
      const typingData: ITypingUser = {
        type: this.dialog.type,
        typeId: this.dialog.typeId,
        replyUserId: this.current_user.id,
        fullname: this.current_user.name,
      };
      this.chatDialogService.setTypingStatus(typingData);
    }
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      if (this.messageInput && this.messageInput.nativeElement && !this.isTouchDevice()) {
        this.messageInput.nativeElement.focus();
      }
    });
    this.swiperViewes.changes.pipe(takeUntil(this.u$)).subscribe(() => {
      this.swiperViewes.forEach((item) => item.update());
      //this.swiper.update();
    });
    // this.scrollWithDelay();
    // this.messagesQuery.changes.pipe(first()).subscribe(this.scrollWithDelay.bind(this));

    // this.typingSubject.pipe(takeUntil(this.u$), delay(500)).subscribe((textValue) => this.emitTypingEvent(textValue));
  }

  get isEdge() {
    return navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
  }

  get audios() {
    return this.files.filter((file) => file.name.indexOf('audio') !== -1);
  }

  get others() {
    return this.files.filter((file) => file.name.indexOf('audio') === -1);
  }

  get isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  get isTaskChat() {
    return this.realDialog?.type === 'task-comments';
  }

  getExt(file) {
    return file.name.split('.').pop();
  }

  getUrl(file) {
    return URL.createObjectURL(file);
  }

  async startAudioRecord() {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        this.stream = stream;
      });
    if (this.stream) {
      const options: any = {
        type: 'audio',
        mimeType: 'audio/wav',
        numberOfAudioChannels: this.isEdge ? 1 : 2,
        checkForInactiveTracks: true,
        bufferSize: 16384,
      };
      if (this.isSafari || this.isEdge) {
        options.recorderType = RecordRTC.StereoAudioRecorder;
      }

      if (navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
        options.sampleRate = 48000; // or 44100 or remove this line for default
      }

      if (this.isSafari) {
        options.sampleRate = 44100;
        options.bufferSize = 4096;
        options.numberOfAudioChannels = 2;
      }

      if (this.recorder) {
        this.recorder.destroy();
        this.recorder = null;
      }
      this.recorder = new RecordRTC.RecordRTCPromisesHandler(this.stream, options);
      await this.recorder.startRecording();
      this.recording = true;
      this.cdr.detectChanges();
    }
  }

  async stopAudioRecord() {
    if (this.recorder) {
      await this.recorder.stopRecording();
      const blob = await this.recorder.getBlob();
      if (this.recorder) {
        this.recorder.destroy();
        this.recorder = null;
      }
      blob.name = `audio${this.files.length + 1}`;
      this.files.push(blob);
      this.recording = false;
      this.cdr.detectChanges();
    }
  }

  scrollWithDelay() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  imagesLoaded(id) {
    this.loadMessagesCount[id] = true;
    if (this.messages[this.messages.length - 1].id === id && this.isUserNearBottom()) {
      if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
        this.scrollToBottom();
      }
    }
    if (this.first) {
      if (Object.keys(this.loadMessagesCount).length === this.messages.length) {
        if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
          this.scrollToBottom();
          this.first = false;
        } else {
          this.firstNotLoaded = true;
        }
      }
    }

    if (this.scrollNew > 0 && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      this.scrollNew--;
    }
    /*if (this.lastIndex && this.chatListRefs && this.lastIndex !== (this.chatListRefs.length - 1) && this.chatListRefs.toArray()[this.lastIndex]) {
      this.scrollToIndex(this.lastIndex);
      this.lastIndex = null;
      this.loadOnScroll = false;
    }*/
  }

  scrollToBottom() {
    try {
      // this.messagesWrapper.nativeElement.scrollIntoView(true);
      if (this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
        this.zone.runOutsideAngular(() => {
          //this.chatListRefs.last.nativeElement.scrollIntoView({block: 'end', behavior: 'auto', inline: 'start'});
        });
        this.hide = false;
        this.cdr.detectChanges();
      }
      /*const el = this.messagesWrapper.nativeElement as Element;
      el.scrollTop = el.scrollHeight;*/
      // window.scrollTo(0, 1);
    } catch (err) {}
  }

  isMobileSafari() {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  edit(message: Comment) {
    this.editingMessage = message;
    this.quotingMessage = null;
    this.userMessage = message.text;
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.check();
  }

  remove(message: Comment) {
    this.ngxService.startLoader(this.LOADER_ID);
    this.chatDialogService.removeMessage(message).then(() => this.ngxService.stopLoader(this.LOADER_ID));
  }

  forward(message: Comment) {
    this.forwardingMessage = message;
    this.check();
  }

  forwardTo(record: User | { type: ChatDialogType; info: any; messages: any[] }, dialog: Comment) {
    let id = null,
      [mine_id, their_id] = dialog?.participants || [];
    //id = typeof their_id !== 'undefined' ? their_id : user.id;

    if (!this.forwardingMessage) {
      return;
    }

    const text = FORWARDED_MESSAGE;

    /*if (this.forwardingMessage.text === FORWARDED_MESSAGE) {
      text = this.forwardingMessage.attached.messages[0].message.text;
    }*/

    const type = 'users-chat';
    this.chatDialogService
      .forwardMessage({
        text,
        record,
        dialog,
        type,
        attached: {
          messages: [this.forwardingMessage],
          uploads: [],
        },
      })
      .pipe(first())
      .subscribe(() => {
        this.forwardingMessage = null;
        this.ngxService.stopLoader(this.LOADER_ID);
        //this.chatService.updateDialogs.emit();
        this.onForward.emit(record);
        this.check();
      });
  }

  onTouch($event) {
    this.zone.runOutsideAngular(() => {
      if (this.messageInput) {
        this.messageInput.nativeElement.blur();
      }
    });
  }

  quote(message: Comment) {
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.quotingMessage = message;
    this.editingMessage = null;
    this.userMessage = '';
    this.check();
  }

  scrollToQuoted(quote: ChatMessageQuote) {
    // TODO: provide scroll to quoted message and highlight it
  }

  get chatRecord(): any {
    // if (this.record.hasOwnProperty('type')) {
    //   // @ts-ignore
    //   return this.record.info;
    // } else {
    return this.record;
    // }
  }

  scrollToIndex(index) {
    if (this.chatListRefs) {
      const arrChatList = this.chatListRefs.toArray();
      if (index === arrChatList.length - 1) {
        return false;
      }
      if (arrChatList[index]) {
        this.zone.runOutsideAngular(() => {
          //this.messagesWrapper.nativeElement.scrollTop = arrChatList[index].nativeElement.offsetTop;
        });
        return true;
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.scrollNew > 0 && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      this.scrollNew--;
    }
    if (this.firstNotLoaded && this.chatListRefs && this.chatListRefs.last && this.chatListRefs.last.nativeElement) {
      this.scrollToBottom();
      this.firstNotLoaded = false;
    }
    if (this.lastIndex && this.chatListRefs && this.messagesWrapper) {
      if (this.scrollToIndex(this.lastIndex)) {
        this.lastIndex = null;
        this.loadOnScroll = false;
      }
    }
  }

  private isUserNearBottom(): boolean {
    /* if (this.messageInput) {
       const threshold = 150;
       const position = this.messagesWrapper.directiveRef.nativeElement.scrollTop;
       const height = this.messagesWrapper.nativeElement.scrollHeight - this.messagesWrapper.nativeElement.offsetHeight;
       return (position / height) > 0.85;
     }*/
    return true;
  }

  onScroll($event) {
    if (this.isTouchDevice()) {
      this.zone.runOutsideAngular(() => {
        // this.messageInput.nativeElement.blur();
      });
    }
  }

  onScrollTop($event) {
    // rework this func
    this.scrolledToBottom = false;
    if (!this.loadOnScroll) {
      this.loadOnScroll = true;
      this.chatDialogService.getMessages({ page: this.page++ });
    }
  }

  get chatType(): 'user' | 'guild' | 'clan' | 'alliance' {
    // @ts-ignore
    return this.record.type ? this.record.type : 'user';
  }

  nameClick() {}

  ngOnDestroy() {
    this.u$.next();
    this.u$.unsubscribe();
  }

  trackMessagesBy(i, { id, user, replyUser }) {
    return `${id}-from-${user.id}-to-${replyUser ? replyUser.id : i}`;
  }

  constructor(
    private chatDialogService: ChatDialogService,
    private messagesService: MessagesService,
    private chatService: ChatService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private soundService: SoundService,
    private feathersService: FeathersService,
    private zone: NgZone,
    private router: Router,
    private store: Store<AppState>,
    private toggler: pagesToggleService
  ) {}

  cancelTask(message: any = null) {
    this.approve = false;
    this.cancel = true;
    if (message) {
      this.chatDialogService.dialog.stepId = message.taskStep.id;
    }
    this.zone.runOutsideAngular(() => {
      this.messageInput.nativeElement.focus();
    });
    this.quotingMessage = message;
    this.editingMessage = null;
    this.sendMessage(null);
    /* this.chatDialogService.sendAppliedTask(false);*/
  }

  openTicket(message: any = null) {
    this.userMessage = 'Проблема не решена!';
    // this.zone.runOutsideAngular(() => {
    //   this.messageInput.nativeElement.focus();
    // });
    this.editingMessage = null;
    this.sendMessage(null);
    /* this.chatDialogService.sendAppliedTask(false);*/
  }

  async approveTask(message: any = null) {
    //this.approve = true;
    this.cancel = false;
    if (message) {
      this.chatDialogService.dialog.stepId = message.taskStep.id;
    }
    this.chatDialogService.sendAppliedTask(true);
  }

  ngOnInit() {
    this.initializeValues();

    this.messagesService.subjects['users-chat/list'].pipe(takeUntil(this.u$)).subscribe((dialogs) => {
      this.zone.run(() => {
        this.dialogs = dialogs;
        this.chatDialogs = this.dialogs?.data;
        this.cdr.markForCheck();
      });
    });

    this.messagesService.findChats({});

    this.feathersService.currentUserSubject.pipe(takeUntil(this.u$), first()).subscribe((user) => {
      this.current_user = user;
      this.setupChatSubscriptions();
    });
  }

  private initializeValues() {
    this.lastIndex = null;
    this.loadOnScroll = false;
    this.scrollNew = 0;
    this.first = true;
    this.loading = true;
    this.hide = true;
    this.cdr.detectChanges();
  }

  private setupChatSubscriptions() {
    this.all$ = this.chatService.getChatCommunity({ type: 'all' });
    this.typingUsers$ = this.chatDialogService.activateTypingStatus();

    this.typingUsers$.pipe(takeUntil(this.u$)).subscribe(({ type, data }) => {
      if (type === ResponseType.TYPING && data.replyUserId !== this.current_user.id) {
        this.handleTypingStatus(data);
      }
    });

    this.chatDialogService.update$
      .pipe(
        takeUntil(this.u$),
        tap(() => this.updateMessageWrapper()),
        filter(Boolean),
        mergeMap(() => this.dialog$),
        mergeMap(() =>
          this.chatDialogService.activateChat({
            record: this.record,
            current_user: this.current_user,
            dialog: this.dialog,
          })
        )
      )
      .subscribe(({ messages, type, lastIndex }) => {
        this.handleChatUpdate(messages, type, lastIndex);
      });
  }

  private handleTypingStatus(data: any) {
    if (data.type === 'support') {
      this.setTypingReplyUser(data);
    } else if (data.typeId === this.dialog?.typeId) {
      this.setTypingReplyUser(data);
    }
  }

  private updateMessageWrapper() {
    if (this.messagesWrapper) {
      this.messagesWrapper.update();
      this.messagesWrapper.scrollToTop();
    }
  }

  private handleChatUpdate(messages: any[], type: ResponseType, lastIndex: number) {
    this.loadOnScroll = false;
    this.messages = messages || [];
    this.loading = !messages?.length;
    this.hide = !this.loading;

    if (this.messages.length > 0) {
      this.realDialog = {
        ...this._dialog,
        ...this.messages[0],
        message: this.messages[0],
      };
      this.chatDialogService.dialog = this.realDialog;
    }

    if ([ResponseType.CREATED, ResponseType.REMOVED, ResponseType.PATCHED].includes(type)) {
      this.scrollNew = 2;
    }

    this.lastIndex = lastIndex || this.lastIndex;
    this.cdr.detectChanges();

    this.handleDialogActions();
  }

  private handleDialogActions() {
    const dialog = this.chatDialogService.dialog;

    if (dialog && (dialog.approve || dialog.cancel)) {
      if (dialog?.approve) {
        dialog.approve = null;
        this.approveTask();
      }

      if (dialog?.cancel) {
        dialog.cancel = null;
        this.cancelTask();
      }

      const queryParams = { approve: null, cancel: null };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  filterTypingUsers() {
    const checkDialog = (type, typeId) => (type === 'support' ? true : typeId === this.dialog?.typeId);
    const filteredByChat = this.allTypingUsers.filter(({ type, typeId }) => checkDialog(type, typeId));
    this.currentTypingUsers = filteredByChat.splice(0, 3);
  }

  private setTypingReplyUser(user: ITypingUser): void {
    this.typingReplyUser = user;
    this.cdr.detectChanges();
    clearTimeout(this.timeoutTyping);
    this.timeoutTyping = setTimeout(() => {
      this.typingReplyUser = null;
      this.cdr.detectChanges();
    }, 1500);
  }

  messageLoaded(message) {
    //this.loading = false;
    //this.ngxService.stopLoader('messages-loader');
  }

  check() {
    this.cdr.markForCheck();
  }

  log(...args) {}

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  removeUploads: any[] = [];

  addToRemoveUpload(upload, id) {
    if (this.editingMessage) {
      this.editingMessage.attached.uploads.splice(id, 1);
      this.removeUploads.push(upload);
    }
  }

  removeUpload() {
    if (this.editingMessage) {
      //this.editingMessage.attached.uploads.splice(id, 1);
      this.removeUploads.forEach((upload) => {
        if (upload.id && upload.typeId) {
          this.chatDialogService.removeUpload(upload);
        } else {
          this.files.splice(this.files.indexOf(upload), 1);
        }
      });
    }
  }

  closeEditingMode() {
    this.editingMessage = null;
    this.userMessage = '';
  }

  sendMessage(event: Event): void {
    if (event) {
      this.preventDefaultEvent(event);
    }

    if (this.isInvalidMessage()) {
      this.error = true;
      this.cdr.detectChanges();
      return;
    }

    this.first = true;
    this.zone.runOutsideAngular(() => {
      this.blurInput();
    });

    if (this.editingMessage) {
      this.handleEditMessage();
    } else {
      this.handleSendMessage();
    }
  }

  private preventDefaultEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private isInvalidMessage(): boolean {
    const hasNoMessageContent: boolean = !this.userMessage && this.files.length === 0;
    const isEditingEmptyMessage: boolean = this.editingMessage && this.editingMessage.attached?.uploads?.length === 0;
    return hasNoMessageContent && (!this.editingMessage || isEditingEmptyMessage);
  }

  private blurInput(): void {
    if (this.messageInput) {
      this.messageInput.nativeElement.blur();
    }
  }

  private handleEditMessage(): void {
    const msg: any = this.editingMessage;
    const msgId: string = msg.id;

    if (this.isMessageChanged(msg)) {
      this.removeUpload();
      this.chatDialogService.editMessage({ ...msg, text: this.userMessage }).then(() => {
        this.uploadFiles(msgId);
        this.resetMessage();
        this.focusInput();
      });
    }
  }

  private isMessageChanged(msg: any): boolean {
    return msg.text !== this.userMessage || this.files.length > 0 || this.removeUploads.length > 0;
  }

  private uploadFiles(msgId: string): void {
    const tempFiles: File[] = [...this.files];
    const imgFiles: File[] = tempFiles.filter((item: File) => ['svg', 'jpeg', 'jpg', 'png', 'gif'].includes(item.name.split('.').pop()!));
    this.files = [];

    if (tempFiles.length) {
      this.chatDialogService.uploadMedia({ uploads: tempFiles, type: this.dialog?.type }).subscribe((uploads: any[]) => {
        if (uploads.length > 0) {
          this.chatDialogService.service.patch(msgId, { text: this.userMessage, attached: { uploads } }).then(() => {
            this.cdr.markForCheck();
          });
        }
      });
    }
  }

  private handleSendMessage(): void {
    this.chatDialogService
      .sendMessage({
        text: this.userMessage,
        type: this.dialog?.type,
        attached: {
          messages: this.quotingMessage ? [this.quotingMessage] : [],
          uploads: this.files,
        },
        canceled: this.cancel,
        approved: this.approve,
      })
      .pipe(first())
      .subscribe(() => {
        this.resetMessage();
        this.scrollWithDelay();
        this.focusInput();
      });
  }

  private resetMessage(): void {
    this.userMessage = '';
    this.cancel = false;
    this.approve = false;
    this.quotingMessage = null;
    this.error = false;
    this.files = [];
    this.editingMessage = null;
    this.check();
  }

  private focusInput(): void {
    if (this.messageInput) {
      this.messageInput.nativeElement.focus();
    }
  }

  files: File[] = [];

  onSelect(event: { addedFiles: File[] }) {
    this.files = [...this.files, ...event.addedFiles];
  }

  onRemove(event: File) {
    this.files = this.files.filter((file) => file !== event);
  }

  onSendMessageTask(data: { message: string; answer: any }) {
    this.userMessage = data.message;
    this.chatDialogService
      .sendMessage({
        text: this.userMessage,
        type: this.dialog?.type,
        canceled: this.cancel,
        approved: this.approve,
        answer: data.answer,
        attached: {
          messages: this.quotingMessage ? [this.quotingMessage] : [],
          uploads: this.files,
        },
      })
      .pipe(first())
      .subscribe(() => {
        this.userMessage = '';
        this.cancel = false;
        this.approve = false;
        this.quotingMessage = null;
        this.error = false;
        this.files = [];
        this.check();
        this.scrollWithDelay();
      });
  }

  onCreateAutoOrder(data: { recommendedOrderId: number }) {
    const queryParams = {
      action: 'basket',
      action_type: 'task',
      action_id: data.recommendedOrderId,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
    setTimeout(() => {
      this.toggler.toggleQuickView();
    }, 300);
  }

  onReadMessages(): void {
    if (this.messages && this.messages.length) {
      const readedMessages = this.messages.filter((message) => !message.readAt && !message.senderaddressId);
      console.log(readedMessages);
      readedMessages.forEach((message) => this.chatDialogService.markIsReadMessage(message));
    }
  }
}
