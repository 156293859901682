import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserEmojiComponent } from './user-emoji/user-emoji.component';
import { UserInfoComponent } from './user-info.component';

export * from './user-avatar/user-avatar.component';

@NgModule({
  imports: [CommonModule, TooltipModule.forRoot()],
  declarations: [UserInfoComponent, UserAvatarComponent, UserEmojiComponent],
  exports: [UserInfoComponent, UserAvatarComponent, UserEmojiComponent],
})
export class UserInfoModule {}
