import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SCHOOL_URL } from '@Env/environment';
import { Award } from '@Mesh/core/models/award';
import { Transaction } from '@Mesh/core/models/transaction';

@Injectable({ providedIn: 'root' })
export class AwardService {
  private BASE_URL = `${API_SCHOOL_URL}/awards`;

  constructor(private http: HttpClient) {}

  all(order_direction?: string, order_field?: string, limit?: number): Observable<Award[]> {
    const params = {};

    if (order_direction && order_field) {
      params['order_direction'] = order_direction;
      params['order_field'] = order_field;
    }

    if (limit) {
      params['limit'] = limit;
    }

    return this.http.get<Award[]>(`${this.BASE_URL}`, { params }).pipe(
      map((data) => {
        return data['data'].map((item) => {
          return new Award().deserialize(item);
        });
      })
    );
  }

  find(id) {
    return this.http.get<Award>(`${this.BASE_URL}/` + id).pipe(
      map((data) => {
        return new Award().deserialize(data['data']);
      })
    );
  }

  buy({ id: award_id }) {
    return this.http.post<Award>(`${this.BASE_URL}/buy`, { award_id });
  }

  get(id) {
    return this.http.get<Award>(`${this.BASE_URL}/${id}`, {}).pipe(map((data) => new Award().deserialize(data['data'])));
  }

  last() {
    return this.http.get<Award>(`${this.BASE_URL}/last`).pipe(
      map((data) => {
        return new Award().deserialize(data['data']);
      })
    );
  }

  getHistoryOrder(params: any = {}): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(`${API_SCHOOL_URL}/transactions?type=shop`, { params }).pipe(
      map((data) => (data as any).data),
      map((history) =>
        history.map((item) => {
          return {
            ...item,
            award: new Award().deserialize(item.award),
          };
        })
      )
    );
  }
}
