import { Component, OnInit, Input } from '@angular/core';
import { UserSchool } from '../../../../core/models/user-school';

@Component({
  selector: 'app-user-emoji',
  templateUrl: './user-emoji.component.html',
  styleUrls: ['./user-emoji.component.scss'],
})
export class UserEmojiComponent implements OnInit {
  _user: UserSchool;
  constructor() {}

  @Input()
  set user(value: UserSchool) {
    this._user = value;
  }

  get user() {
    return this._user;
  }

  ngOnInit() {}
}
