import { Component, OnInit, Input } from '@angular/core';
import { UserSchool } from '../../../core/models/user-school';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input() user: UserSchool;
  constructor() {}

  ngOnInit() {}
}
