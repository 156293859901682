import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '@Mesh/core/models/user';
import { API_USER_URL, IMAGE_CLOUD_URL, IMAGE_URL } from '@Env/environment';
import { UserStat } from '@Mesh/core/models/user-stat';
import { Observable, of, throwError, Subject } from 'rxjs';
import { AuthenticationService } from '@Mesh/core/services/authentication.service';
import { UserPoint } from '@Mesh/core/models/user-point';
import { Cacheable } from 'ngx-cacheable';
import { IPageableList } from '@Mesh/core/models/pageable';
import { stringifyObject } from '@Mesh/core/services/util';
import {
  DeleteUserCriteria,
  GetUserCriteria,
  PostSmsUser,
  PostUpdateUser,
  PostUser,
  PostUserPermissions,
  PostVerifyUser,
} from '@Mesh/core/models/request';
import { Content } from '../models/content';

@Injectable({ providedIn: 'root' })
export class UserService {
  users: User[] = [];
  onResponse$ = new Subject();

  private BASE_URL = `${API_USER_URL}/user`;
  private API_CONTENT_URL = `${IMAGE_URL}/content`;

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getUsers(param: Partial<GetUserCriteria>): Observable<IPageableList<User>> {
    const stringData = stringifyObject(param);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<IPageableList<User>>(`${this.BASE_URL}`, { params }).pipe(
      map((res) => {
        res.content.map((user) => this.setPermissionsIcons(user));
        return res;
      })
    );
  }

  addUser(user: PostUser): Observable<User> {
    return this.http.post<User>(`${this.BASE_URL}`, user);
  }

  sendSmsRemoveUser(body: DeleteUserCriteria): Observable<any> {
    return this.http.delete<any>(`${this.BASE_URL}`, { body } as any);
  }

  verifyAddUser(body: PostVerifyUser): Observable<User> {
    return this.http.post<User>(`${this.BASE_URL}/verify/addNewUser`, body);
  }

  verifyRemoveUser(body: PostVerifyUser): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/verify/removeUser`, body);
  }

  sendSmsUser(body: PostSmsUser): Observable<User> {
    return this.http.post<User>(`${this.BASE_URL}/sms/addNewUser`, body);
  }

  checkExistenceUser(phoneNumber: string): Observable<{ canBeAuthorized: boolean }> {
    return this.http.post<{ canBeAuthorized: boolean }>(`${this.BASE_URL}/check/pre-authorization/${phoneNumber}`, null);
  }

  getUser(id: string, withAddress?: boolean, addressSize?: number): Observable<User> {
    const stringData = stringifyObject({ withAddress, addressSize });
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<User>(`${this.BASE_URL}/${id}`, withAddress && addressSize ? { params } : {});
  }

  updateUser(id: number, body: PostUpdateUser): Observable<User> {
    return this.http.patch<User>(`${this.BASE_URL}/${id}`, body);
  }

  upsertUserPermissions(id: number, body: PostUserPermissions): Observable<User> {
    return this.http.patch<User>(`${this.BASE_URL}/${id}/permissions`, body);
  }

  getClientIdsForActiveUsers(): Observable<{ clientIds: number[] }> {
    return this.http.get<{ clientIds: number[] }>(`${this.BASE_URL}/clientSapIdList`);
  }

  changeAvatar(file: File): Observable<Content> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<Content>(`${this.API_CONTENT_URL}/image`, formData, {
      headers: {
        'no-content-type': 'true',
      },
    });
  }

  // addUser(user: User) {
  //
  //     for (const i in this.users) {
  //         const item = this.users[i];
  //         if (item.id === user?.id) {
  //             this.users[i] = user;
  //         } else {
  //             this.users.push(user);
  //         }
  //     }
  // }

  // getUser(id) {
  //     return this.users.find((user: User) => user.id === id);
  // }

  user(fresh: boolean): Observable<User> {
    if (this.authService.currentUser && !fresh) {
      return of(this.authService.currentUser);
    }
    return this.http.get<any>(`${this.BASE_URL}`).pipe(
      map((response) => {
        // NOTE: response is of type SomeType
        const user = new User().deserialize(response.data);
        this.authService.currentUser = new User().deserialize(user);
        return user;
      }),
      catchError((error) => {
        return throwError(error);
      })
    ); // end of pipe
  }

  @Cacheable()
  get(id): Observable<User> {
    return this.http.get<User>(`${this.BASE_URL}/` + id).pipe(
      map((data) => {
        return new User().deserialize(data);
      })
    );
  }

  update(user: User) {
    return this.http.patch<any>(`${this.BASE_URL}`, user);
  }

  phone(phone: string, code: string) {
    return this.http.patch<any>(`${this.BASE_URL}/phone`, { phone, code });
  }

  avatar(formData: FormData) {
    return this.http.post<any>(`${this.BASE_URL}/avatar`, formData).pipe(
      tap((resp) =>
        this.onResponse$.next({
          type: 'avatar',
          data: resp,
        })
      )
    );
  }

  character(character_id: number) {
    return this.http.post<any>(`${this.BASE_URL}/character`, { character_id }).pipe(
      map((data) => {
        return new User().deserialize(data['data']);
      })
    );
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  points(): Observable<UserPoint[]> {
    const userPoints: UserPoint[] = [];
    let points = 0;
    for (let i = 240; i < 300; i++) {
      const userPoint = new UserPoint();
      userPoint.id = i;
      userPoint.date = i.toString();
      points += Math.round(this.getRandomArbitrary(10, 50));
      userPoint.user_points = points;
      userPoints.push(userPoint);
    }
    return of(userPoints);
    /*return this.http.get<UserPoint[]>(`${this.BASE_URL}/points`)
            .pipe(
                map(data => {
                  return data['data'].map(item => {
                    const userPoint = new UserPoint().deserialize(item);
                    return userPoint;
                  });
                })
            );*/
  }

  stat() {
    const userState = new UserStat();
    userState.rank = 100;
    userState.earn_for_month = 93;
    userState.level = 99;
    return of(userState);
    // return this.http.get<UserStat>(`${this.BASE_URL}/stat`);
  }

  private setPermissionsIcons(user: User): User {
    const icons = [];
    if (user.type === 'MANAGER') {
      icons.push('manager');
    }
    if (user.permissions && user.permissions.length > 0) {
      const p = user.permissions[0].permissions;
      if (p.includes('ADDR_BOSS')) {
        icons.push('addr-boss');
      }
      if (p.includes('ADD_USER')) {
        icons.push('add-user');
      }
      if (p.includes('ORDR_TSK')) {
        icons.push('order-tsk');
      }
    }
    user.permissionsIcons = icons;
    return user;
  }
}
