import { Answer } from './answer';
import { Deserializable } from './deserializable';

export class Question implements Deserializable {
  get time_in_seconds(): number {
    if (this._time) {
      return +this._time
        .split(':')
        .map((time) => +time)
        .reduce((acc, time) => 60 * acc + +time);
    }
  }

  get time() {
    return this._time;
  }

  set time(value: string) {
    this._time = value;
  }

  static TYPE_CHOOSE = 'single';
  static TYPE_MULTIPLE_CHOOSE = 'plural';
  static TYPE_RANGE = 'sort';
  static TYPE_TEXT = 'text';
  static TYPE_NUMBER = 'number';
  static TYPE_EQUIVALENT = 'match';
  static TYPE_FREE = 'free';

  id?: number;
  _time?: string;
  question?: string;
  question_type?: string;
  progress?: number;
  right_answer_text?: string;
  wrong_answer_text?: string;
  attempts?: number;
  answers?: Answer[];

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.answers) {
      this.answers = input.answers.map((answer) => {
        return new Answer().deserialize(answer);
      });
    }
    return this;
  }
}
