import * as moment from 'moment';
import 'moment-duration-format';

import { Deserializable } from './deserializable';
import { User } from './user';
import { Tag } from './tag';
import { Material } from './material';
// import { UserModule } from './user-module';
import { declOfNum } from '@Mesh/shared/helpers/formatter.helpers';

export interface IModule extends Deserializable {
  id?: number;
  description?: string;
  complexity?: number;
  max_points?: number;
  _training_time?: number;
  calendar_time?: number;
  hit?: boolean;
  new?: boolean;
  recommend?: boolean;
  name?: string;
  code?: string;
  result?: string;
  purpose?: string;
  teacher?: User;
  tags?: Tag[];
  materials?: Material[];
  num_users?: number;
  appointed?: boolean;
  start?: string;
  end?: string;
  likes_count?: number;
  dislikes_count?: number;
  liked?: boolean;
  disliked?: boolean;
  user_module?: any;
  user_course_module?: any;
  type?: string;
  training_time: number;
  tags_slugs: string[];
  image?: {
    list?: string;
    view?: string;
    main?: string;
  };
}

export class Module implements IModule {
  constructor(module?: Partial<IModule>) {
    if (!module) {
      return;
    }
    this.deserialize(module);
  }

  get training_time() {
    return moment['duration'](this._training_time, 'minutes')['format']('h:mm');
  }

  set training_time(time) {
    this._training_time = time;
  }

  get max_points_formatted() {
    return (this.max_points ? this.max_points : 0) + ' ' + declOfNum(this.max_points, ['балл', 'балла', 'баллов']);
  }

  id?: number;
  description?: string;
  complexity?: number;
  max_points?: number;
  _training_time?: number;
  calendar_time?: number;
  hit?: boolean;
  new?: boolean;
  recommend?: boolean;
  name?: string;
  code?: string;
  result?: string;
  purpose?: string;
  task_user_id?: number;
  teacher?: User;
  tags?: Tag[];
  materials?: Material[];
  num_users?: number;
  appointed?: boolean;
  start?: string;
  end?: string;
  created_at?: number;
  updated_at?: number;
  likes_count?: number;
  dislikes_count?: number;
  liked?: boolean;
  disliked?: boolean;
  user_module?: any;
  user_course_module?: any;
  type?: string;
  tags_slugs: string[];
  image?: {
    list?: string;
    view?: string;
    main?: string;
  };

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.teacher) {
      this.teacher = new User().deserialize(input.teacher);
    }
    // if (input.user_module) {
    //   this.user_module = new UserModule().deserialize(input.user_module);
    // }
    // if (input.user_course_module) {
    //   this.user_course_module = new UserModule().deserialize(input.user_course_module);
    // }
    if (input.tags) {
      const tags: Tag[] = [];
      input.tags.map((data) => {
        tags.push(new Tag().deserialize(data));
      });
      this.tags = tags;

      this.tags_slugs = this.tags.map((tag) => {
        return tag.slug;
      });
    }

    if (input.materials) {
      const materials: Material[] = [];
      input.materials.map((data) => {
        materials.push(new Material().deserialize(data));
      });
      this.materials = materials;
    }
    return this;
  }
}

export enum ModuleType {
  COURSE = 'course',
  VIDEO = 'video',
  EXAM = 'exam',
  SCORM = 'scorm',
  BOOK = 'book',
  ARTICLE = 'article',
  NEWS = 'news',
  PODCAST = 'podcast',
  FILM = 'film',
}
