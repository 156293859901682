import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { DocumentService } from '@Mesh/core/services/document.service';
import { AuthenticationService } from '@Mesh/core/services';
import {
  DocumentActionsTypes,
  DocumentDownloadRequest,
  DocumentDownloadSuccess,
  DocumentLoadError,
  DocumentLoadRequest,
  DocumentLoadSuccess,
  DocumentSetActive,
  DocumentSetActiveSuccess,
  DocumentSetTotalCount,
  DocumentSignedError,
  DocumentSignedRequest,
  DocumentSignedSuccess,
  DocumentSignError,
  DocumentSignRequest,
  DocumentSignSuccess,
} from '@Mesh/store/document/document.actions';
import { documentSelectors } from '@Mesh/store/document/document.selectors';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';

@Injectable()
export class DocumentEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private documentService: DocumentService,
    private authService: AuthenticationService
  ) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<DocumentLoadRequest>(DocumentActionsTypes.DocumentLoadRequest),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([action, outlet]) => {
      return of(outlet).pipe(
        filter((outlet) => !!outlet),
        mergeMap(() => this.documentService.getDocuments({ ...action.payload, clientId: outlet.client.id })),
        mergeMap((res) => [new DocumentLoadSuccess({ data: res.content }), new DocumentSetTotalCount({ totalCount: res.totalElements })])
      );
    }),
    catchError((error) => of(new DocumentLoadError(error)))
  );

  @Effect()
  signIQRetail: Observable<Action> = this.actions$.pipe(
    ofType<DocumentSignRequest>(DocumentActionsTypes.DocumentSignRequest),
    switchMap((action) => this.documentService.signIQRetail(action.payload.id)),
    map((action) => new DocumentSignSuccess({ identifierId: action.identifierId })),
    catchError((error) => of(new DocumentSignError(error)))
  );

  @Effect()
  signedIQRetail: Observable<Action> = this.actions$.pipe(
    ofType<DocumentSignedRequest>(DocumentActionsTypes.DocumentSignedRequest),
    withLatestFrom(this.store$.select(documentSelectors.getIdentifierId)),
    switchMap(([action, identifierId]) =>
      this.documentService.signedIQRetail({
        ...action.payload.body,
        identifier: identifierId,
        documentIds: [action.payload.id],
      })
    ),
    map(() => new DocumentSignedSuccess()),
    catchError((error) => of(new DocumentSignedError(error)))
  );

  @Effect()
  download: Observable<Action> = this.actions$.pipe(
    ofType<DocumentDownloadRequest>(DocumentActionsTypes.DocumentDownloadRequest),
    tap((action) => this.documentService.downloadFile(action.payload.url, action.payload.filename)),
    map(() => new DocumentDownloadSuccess()),
    catchError((error) => of(new DocumentLoadError(error)))
  );

  @Effect()
  setActive: Observable<Action> = this.actions$.pipe(
    ofType<DocumentSetActive>(DocumentActionsTypes.DocumentSetActive),
    map(() => new DocumentSetActiveSuccess()),
    catchError((error) => of(new DocumentLoadError(error)))
  );

  /*    @Effect()
        setActiveSuccess: Observable<Action> = this.actions$.pipe(
            ofType<DocumentSetActiveSuccess>(DocumentActionsTypes.DocumentSetActiveSuccess),
            map(() => new DocumentDetailsLoadRequest()),
            catchError(error => of(new DocumentLoadError(error)))
        );*/
}
