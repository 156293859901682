import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SCHOOL_URL } from '../../../../../../environments/environment';
import { UserTaskSchool } from '@Mesh/core/models/user-task-school';

@Injectable()
export class TrainingTaskWidgetService {
  constructor(private http: HttpClient) {}

  getTaskSchoolList(
    isDone: string | number,
    taskLevel: number | null,
    availableOnly = false,
    gradesOnly = false
  ): Observable<UserTaskSchool[]> {
    const params = {};
    if (isDone !== null) {
      params['isDone'] = +isDone;
    }
    if (taskLevel !== null) {
      params['levelId'] = taskLevel;
    }
    if (availableOnly) {
      params['availableOnly'] = availableOnly;
    }
    if (gradesOnly) {
      params['gradesOnly'] = gradesOnly;
    }
    return this.http.get<any>(`${API_SCHOOL_URL}/tasks`, { params: params }).pipe(
      map((data) => {
        return data['data'].map((item: any) => {
          return new UserTaskSchool().deserialize(item);
        });
      })
    );
  }
}
