import { UpdateAvatarError } from './user.actions';
import { ClearOnQuit, UserActionsTypes, UserActionsUnion } from '@Mesh/store/user/user.actions';
import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { initialState, POSTRequestStatus, POSTRequestStatus as Status, UserState } from '@Mesh/store/user/user.state';

function baseUserReducer(state = initialState, action: UserActionsUnion): UserState {
  switch (action.type) {
    case UserActionsTypes.UsersLoadSuccess:
      return {
        ...state,
        users: action.payload.users,
        usersTotalPages: action.payload.totalPages,
        usersCurrentPage: action.payload.currentPage,
        userLoading: false,
      };
    case UserActionsTypes.UsersLoadPageRequest:
      return {
        ...state,
        userLoading: true,
      };
    case UserActionsTypes.UsersLoadPageRequestFailure:
      return {
        ...state,
        userLoading: false,
      };
    case UserActionsTypes.ClearOnQuit:
      return {
        ...state,
        statuses: {
          smsRemoveUser: POSTRequestStatus.NONE,
          verifyAddUser: POSTRequestStatus.NONE,
          verifyRemoveUser: POSTRequestStatus.NONE,
          sentInvitationAddNewUser: POSTRequestStatus.NONE,
          updateUser: POSTRequestStatus.NONE,
          checkExistenceUser: POSTRequestStatus.NONE,
          upsertPermissions: POSTRequestStatus.NONE,
          retrieveclientIds: POSTRequestStatus.NONE,
        },
      };
    case UserActionsTypes.UsersLoadError:
      return {
        ...state,
        error: action.error,
      };
    case UserActionsTypes.UsersTotalCount:
      return {
        ...state,
        usersTotalCount: action.payload.totalCount,
      };
    case UserActionsTypes.AddUserSuccess:
      return {
        ...state,
        newUserId: action.payload.user.id,
        users: [...state.users, action.payload.user],
      };
    case UserActionsTypes.SendSmsRemoveUserRequest:
      return {
        ...state,
        statuses: { ...state.statuses, smsRemoveUser: Status.SENT },
      };
    case UserActionsTypes.SendSmsRemoveUserSuccess:
      return {
        ...state,
        statuses: { ...state.statuses, smsRemoveUser: Status.SUCCESS },
      };
    case UserActionsTypes.SendSmsRemoveUserError:
      return {
        ...state,
        error: action.error,
        statuses: { ...state.statuses, smsRemoveUser: Status.FAILURE },
      };
    case UserActionsTypes.VerifyAddNewUserRequest:
      return {
        ...state,
        statuses: { ...state.statuses, verifyAddUser: Status.SENT },
      };
    case UserActionsTypes.VerifyAddNewUserSuccess:
      return {
        ...state,
        statuses: { ...state.statuses, verifyAddUser: Status.SUCCESS },
        newUserId: -1,
      };
    case UserActionsTypes.VerifyAddNewUserError:
      return {
        ...state,
        statuses: { ...state.statuses, verifyAddUser: Status.FAILURE },
      };
    case UserActionsTypes.VerifyRemoveUserRequest:
      return {
        ...state,
        statuses: { ...state.statuses, verifyRemoveUser: Status.SENT },
      };
    case UserActionsTypes.VerifyRemoveUserSuccess:
      return {
        ...state,
        statuses: { ...state.statuses, verifyRemoveUser: Status.SUCCESS },
      };
    case UserActionsTypes.VerifyRemoveUserError:
      return {
        ...state,
        statuses: { ...state.statuses, verifyRemoveUser: Status.FAILURE },
      };
    case UserActionsTypes.InvitationAddNewUserRequest:
      return {
        ...state,
        statuses: { ...state.statuses, verifyRemoveUser: Status.SENT },
      };
    case UserActionsTypes.InvitationAddNewUserSuccess:
      return {
        ...state,
        statuses: { ...state.statuses, verifyRemoveUser: Status.SUCCESS },
      };
    case UserActionsTypes.InvitationAddNewUserError:
      return {
        ...state,
        statuses: { ...state.statuses, verifyRemoveUser: Status.FAILURE },
      };
    case UserActionsTypes.CheckExistenceUserRequest:
      return {
        ...state,
        isExistingUser: null,
      };
    case UserActionsTypes.CheckExistenceUserSuccess:
      return {
        ...state,
        isExistingUser: action.payload.canBeAuthorized,
      };
    case UserActionsTypes.CheckExistenceUserError:
      return {
        ...state,
        statuses: { ...state.statuses, checkExistenceUser: Status.FAILURE },
      };
    case UserActionsTypes.UpdateUserRequest:
      return {
        ...state,
        statuses: { ...state.statuses, updateUser: Status.SENT },
      };
    case UserActionsTypes.UpdateUserSuccess:
      const index = state.users.map((review) => review.id).indexOf(action.payload.user.id);
      return {
        ...state,
        users: [
          ...state.users.slice(0, index),
          Object.assign({}, state.users[index], action.payload.user),
          ...state.users.slice(index + 1),
        ],
        statuses: { ...state.statuses, updateUser: Status.SUCCESS },
      };
    case UserActionsTypes.UpdateUserError:
      return {
        ...state,
        error: action.error,
        statuses: { ...state.statuses, updateUser: Status.FAILURE },
      };
    case UserActionsTypes.UpdatePermissionsSuccess:
      const userInd = state.users.map((review) => review.id).indexOf(action.payload.user.id);
      return {
        ...state,
        users: [
          ...state.users.slice(0, userInd),
          Object.assign({}, state.users[userInd], action.payload.user),
          ...state.users.slice(userInd + 1),
        ],
        statuses: { ...state.statuses, upsertPermissions: Status.SUCCESS },
      };
    case UserActionsTypes.UpdatePermissionsError:
      return {
        ...state,
        error: action.error,
        statuses: { ...state.statuses, upsertPermissions: Status.FAILURE },
      };
    case UserActionsTypes.ClientIdsForActiveUsersSuccess:
      return {
        ...state,
        clientIds: action.payload.clientIds,
        statuses: { ...state.statuses, retrieveclientIds: Status.SUCCESS },
      };
    case UserActionsTypes.SetSelectedUser:
      return {
        ...state,
        selectedUserId: action.payload.selectedUserId,
      };
    case UserActionsTypes.ClientIdsForActiveUsersError:
      return {
        ...state,
        error: action.error,
        statuses: { ...state.statuses, retrieveclientIds: Status.FAILURE },
      };

    case UserActionsTypes.ResetUsersValue:
      return {
        ...state,
        ...initialState,
      };

    case UserActionsTypes.UpdateAvatarSuccess:
      return {
        ...state,
        content: action.payload.content,
      };

    case UserActionsTypes.UpdateAvatarError:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function userReducer(state: UserState, action: UserActionsUnion): UserState {
  return withLoadable(baseUserReducer, {
    loadingActionType: [UserActionsTypes.UsersLoadRequest, UserActionsTypes.AddUserRequest, UserActionsTypes.SendSmsRemoveUserRequest],
    successActionType: [UserActionsTypes.UsersLoadSuccess, UserActionsTypes.AddUserSuccess, UserActionsTypes.SendSmsRemoveUserSuccess],
    errorActionType: UserActionsTypes.UsersLoadError,
  })(state, action);
}
