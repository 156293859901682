import 'moment-duration-format';
import { Deserializable } from './deserializable';
import { Module } from './module';
import { Question } from './question';

export class Video extends Module implements Deserializable {
  image?: {
    list?: string;
    view?: string;
  };
  youtube_video_id?: string;
  video?: string;
  video_cdn?: string;
  num_questions?: number;
  questions?: Question[];
  type?: string = 'video';

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    if (input.questions) {
      const questions: Question[] = [];
      input.questions.map((data) => {
        questions.push(new Question().deserialize(data));
      });
      this.questions = questions;
    }
    return this;
  }
}
