import { Deserializable } from './deserializable';
import { Question } from './question';

export class UserAnswer implements Deserializable {
  id: number;
  answer?: any[];
  answer_equal?: any;
  answered?: boolean;
  question?: Question;
  msg?: string;
  attempts?: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.question) {
      this.question = new Question().deserialize(input.question);
    }
    return this;
  }
}
