import * as moment from 'moment';
import 'moment-duration-format';
import { Deserializable } from './deserializable';
import { Module } from './module';

export class Article extends Module implements Deserializable {
  type?: string = 'article';
  image?: {
    list?: string;
    view?: string;
    main?: string;
  };
  content?: JSON;
  get training_time() {
    return moment['duration'](this._training_time, 'minutes')['format']('HH:mm:ss', { trim: false });
  }

  set training_time(time) {
    this._training_time = time;
  }

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    return this;
  }
}
