import { Product } from '@Mesh/core/models/product';
import { PageableList } from '@Mesh/core/models/pageable';
export interface BaseOrder {
  crTime: string;
  sapStatus: string;
  status: OrderStatus;
  docDate: string;
  docDateTime: string;
  delivered: boolean;
  hasRequestInvoice: boolean;
  invoicePdfLinks?: string[];
}

export interface Order extends BaseOrder {
  docNumber: string;
  orderNumber: string;
  countProduct: number;
  cost: number;
}

export interface OrderDetails extends BaseOrder {
  docNumber: string;
  delivQt: number;
  quant: number;
  amount: number;
  netAmount: number;
  delivNetAmount: number;
  delivAmount: number;
  erDate: string;
  unitId: string;
  product: Product;
}

// export enum OrderStatus {
//   inBasket = 0,
//   accepted = 1,
//   finished = 2,
//   denied = 3,
// }

export enum OrderStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  DELIVERED = 'DELIVERED',
  DECLINED = 'DECLINED',
}

// tslint:disable-next-line:no-empty-interface
export interface OrderData extends PageableList<Order> {}
