import { DataService } from '@Mesh/core/services';
import { BasketTaskLeftoverProductsComponent } from '../basket-task-leftover-products/basket-task-leftover-products.component';
import { RecommendOrderBonus } from '@Mesh/store/order/order.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Product } from '@Mesh/core/models/product';
import { Outlet } from '@Mesh/core/models';
import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { CartActions, CartSelectors } from '@Mesh/store/cart';
import { Task } from '@Mesh/core/models/task';
import { TaskProgressLoad } from '@Mesh/store/task/task.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatTaskStepTypes } from '@Mesh/core/models/chat-task-step-types';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-basket-recommend-order-button',
  templateUrl: './basket-recommend-order-button.component.html',
  styleUrls: ['./basket-recommend-order-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketRecommendOrderButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() outlet: Outlet;
  @Input() recommendedOrder: RecommendOrderBonus;
  @Input() activeLoad: boolean = false;
  @Output() activeChanges = new EventEmitter<{ active: boolean; recommendedOrderId: number }>();
  @Output() updatedBasketToOrder = new EventEmitter<any>();
  bsModalRef: any;
  productsStock: Product[];
  readonly taskRecommendedOrder$ = this.store.select(CartSelectors.selectTaskRecommendedOrder);
  protected unsubscribe$ = new Subject();
  hideButtonCalculateRestOfProduct: boolean = false;
  testTask = {
    step: [
      { type: { name: 'CALCULATE_REST_OF_PRODUCT', status: 'NEW' } },
      {
        type: {
          name: 'AUTO_RECOMMENDED_ORDER',
        },
        status: 'NEW',
      },
    ],
  };
  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private dataService: DataService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.taskRecommendedOrder$.pipe(takeUntil(this.unsubscribe$)).subscribe((task) => {
      this.hideButtonCalculateRestOfProduct = !this.hasCalculateRestOfProductStepCheck(task);
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recommendedOrder) {
      if (this.recommendedOrder) {
        this.store.dispatch(new CartActions.LoadTaskRecommendedOrder({ recommendedOrderId: this.recommendedOrder.id }));
        this.loadStockProducts();
      }
    }
  }

  onClickLoadCart(): void {
    this.activeLoad = true;
    this.activeChanges.emit({ active: this.activeLoad, recommendedOrderId: this.recommendedOrder.id });
    this.cdr.markForCheck();
  }

  onUpdateBasketToOrder(): void {
    this.updatedBasketToOrder.emit(this.recommendedOrder.id);
  }

  loadStockProducts(): void {
    let params = {
      clientId: this.outlet.client.id,
      pageSize: 9999,
      addressId: this.outlet.id,
      ignoreStock: true,
      recommendedOrderId: this.recommendedOrder.id,
    };
    this.dataService
      .getProducts(params)
      .pipe(map((data) => data.content))
      .subscribe((data) => (this.productsStock = data));
  }

  handleButtonClick(): void {
    this.taskRecommendedOrder$.pipe(takeUntil(this.unsubscribe$)).subscribe((task) => {
      if (task) {
        const steps = task.step;
        if (steps.length === 2 && steps[0].type.name === ChatTaskStepTypes.CALCULATE_REST_OF_PRODUCT) {
          this.openRestCalculationWindow();
        } else if (steps.length === 3 && steps[0].type.name === ChatTaskStepTypes.PHOTO_MONITORING) {
          this.openChatWindow(task);
        } else {
          console.log('Нет действий для данных условий');
        }
      } else {
        console.log('Нет задачи к данному Рек заказу');
      }
    });
  }

  openRestCalculationWindow(): void {
    this.bsModalRef = this.modalService.show(BasketTaskLeftoverProductsComponent, {
      class: 'modal-dialog-right',
      initialState: {
        products: this.productsStock,
        outlet: this.outlet,
      },
    });
    this.bsModalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.bsModalRef.hide();
    });
    this.bsModalRef.content.updatedCart.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updatedBasketToOrder.emit(this.recommendedOrder.id);
      setTimeout(() => {
        this.bsModalRef.hide();
      }, 1000);
    });
  }

  openChatWindow(task: Task): void {
    this.store.dispatch(new TaskProgressLoad({ taskOutletClientId: task.id }));
    const queryParams = {
      action: 'chat',
      action_type: 'task-comments',
      action_id: task.id,
      task_id: task.taskId,
      address_sap_id: task.addressId,
      client_sap_id: task.clientId,
      sent_by: null,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private hasCalculateRestOfProductStepCheck(task: Task) {
    if (task) {
      const steps = task.step;
      let hasCalculateRestOfProductStepCheck = false;
      if (steps.length === 2) {
        for (let step of steps) {
          if (step.type.name === ChatTaskStepTypes.CALCULATE_REST_OF_PRODUCT) {
            if (step.status === 'ACCEPTED' || step.status === 'CHECKING') {
              hasCalculateRestOfProductStepCheck = true;
            }
          }
        }
      }
      return hasCalculateRestOfProductStepCheck;
    }

    return false;
  }
}
