export interface DelayedOrderHistory {
  userId: number;
  materialId: number;
  comment: string;
  date: Date;
}

export interface DelayedOrderCart {
  id: number;
  materialId: number;
  quantity: number;
  mrc: number;
  unit: string;
  price: number;
  ndsPrice: number;
  discountPrice: number;
  discountNdsPrice: number;
  userId: number;
  type: string;
  salesOrgSapId: string;
  distributorCode: string;
  distributorName: string;
}

export interface DelayedOrder {
  id: number;
  addressId: number;
  status: string;
  remark: string;
  deliveryAt: Date;
  createdAt: Date;
  updatedAt: Date;
  canBeModified: boolean;
  shouldRequestInvoice: boolean;
  history: DelayedOrderHistory[];
  carts: DelayedOrderCart[];
  totalCards: number;
  totalHistory: number;
  totalNdsPrice: number;
  totalDiscountNdsPrice: number;
}

export enum DelayedOrderStatus {
  ON_MODERATION = 'ON_MODERATION',
  ACTIVE = 'ACTIVE',
  PROCESSING = 'PROCESSING',
  PARTIALLY_EXECUTED = 'PARTIALLY_EXECUTED',
  REMOVED = 'REMOVED',
  EXECUTED = 'EXECUTED',
  ERROR = 'ERROR',
}

interface DelayedOrderStatusInfo {
  title: string;
  color: string;
}

export const DelayedOrderStatusMap: Record<DelayedOrderStatus, DelayedOrderStatusInfo> = {
  [DelayedOrderStatus.ACTIVE]: { title: 'Ожидает', color: '#00d1ff' },
  [DelayedOrderStatus.PARTIALLY_EXECUTED]: { title: 'Выполнен частично', color: '#FFFF00' },
  [DelayedOrderStatus.REMOVED]: { title: 'Удален', color: '#FF0000' },
  [DelayedOrderStatus.EXECUTED]: { title: 'Выполнен', color: '#0000FF' },
  [DelayedOrderStatus.ON_MODERATION]: { title: 'На модерации', color: '#7960F0' },
  [DelayedOrderStatus.ERROR]: { title: 'Ошибка', color: '#FF0000' },
  [DelayedOrderStatus.PROCESSING]: { title: 'В процессе', color: '#808080' },
};

export interface DelayedOrderCriteria {
  addressId?: number;
  deliveryAtStart?: string;
  deliveryAtEnd?: string;
  status?: DelayedOrderStatus;
  size?: number;
  page?: number;
}

export interface DelayedOrderUpdatePayload {
  userId?: number;
  delayedOrderId: number;
  remark: string;
  carts: Partial<DelayedOrderCart>[];
}
