import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { SPINNER } from 'ngx-ui-loader';
import { TrainingTaskWidgetService } from '@Mesh/shared/modules/training-tasks/components/training-task-widget/training-task-widget.service';
import { takeUntil } from 'rxjs/operators';
import { AsyncItem } from '../../../../../core/models';
import { UserTaskSchool } from '../../../../../core/models/user-task-school';
import { UserSchoolService } from '../../../../../core/services/user-school.service';
import { UserSchool } from '../../../../../core/models/user-school';
import { Router } from '@angular/router';
import { collapseState } from '@Mesh/utils/animations/collapse-animations';
@Component({
  selector: 'app-training-task-widget',
  templateUrl: './training-task-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./training-task-widget.component.scss'],
  animations: [collapseState],
  providers: [TrainingTaskWidgetService],
})
export class TrainingTaskWidgetComponent implements OnInit, OnDestroy {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  taskCount: number;
  user: UserSchool;
  spinner = SPINNER;
  grade: UserTaskSchool | any;
  _active = false;
  showUpgrade = false;
  taskDataList: Array<UserTaskSchool>;
  isCollapsed = false;

  readonly loaderId: string = 'task-progress-loader';
  private unsubscribe$ = new Subject();
  private _task: AsyncItem<UserTaskSchool>;

  constructor(
    private trainingTaskWidgetService: TrainingTaskWidgetService,
    private userSchoolService: UserSchoolService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.userSchoolService.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.user = user;
      this.taskCount = user.num_tasks;
      this.cdr.markForCheck();
    });

    this.loadTasks();
    this.toggleNavbar();
  }

  set Active(value: boolean) {
    this._active = value;
  }

  get Active(): boolean {
    return this._active;
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.toggleNavbar();
  }

  toggleNavbar() {
    this.isCollapsed = window.innerWidth < 992;
  }

  clickHeader(): void {
    this.Active = !this.Active;
  }

  loadTasks() {
    this.trainingTaskWidgetService
      .getTaskSchoolList(0, null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.taskDataList = data;
        this.showUpgrade = false;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get hasExtendedTasks() {
    return window.innerWidth < 992;
  }
}
